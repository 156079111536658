<template>
  <v-dialog
    v-model="visible"
    @keydown.esc.stop="close"
    scrollable
    max-width="350"
    min-width="350"
  >
    <v-form ref="addPriceForm" @submit.prevent="submit">
      <v-card
        class="overflow-auto pb-5"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h2 class="text--secondary">
              <div v-if="!isManual">Dodaj cijenu</div>
              <div v-else>Dodaj valutu</div>
            </h2>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row v-show="!isManual">
              <v-col>
                <money-input
                  autofocus
                  label="Željena cijena"
                  :rules="[rules.req]"
                  v-model="price"
                  :currency="currency"
                  ref="priceInput"
                  @keyup="calculateBase"
                >
                </money-input>
              </v-col>
              <v-col>
                <money-input
                  label="Osnovica"
                  :rules="[rules.req]"
                  v-model="base"
                  :currency="currency"
                  ref="baseInput"
                  @keyup="calculatePrice"
                >
                </money-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  label="Valuta"
                  v-model="currency"
                  :items="currencies"
                  :rules="[rules.req]"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  label="PDV"
                  :items="priceTaxes"
                  return-object
                  v-model="pdv"
                  item-text="name"
                  item-value="rate"
                  :rules="[rules.req]"
                  @change="calculateBase"
                  :messages="!isTaxed ? 'Firma nije u sustavu PDV-a' : ''"
                >
                </v-select>
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col>
                <v-select
                  multiple
                  label="Porez za artikl na valutu"
                  :items="taxes"
                  :menu-props="{ maxHeight: '400' }"
                  return-object
                  item-text="name"
                  persistent-hint
                  v-model="selectedTaxes"
                  @change="calculatePrice"
                  :rules="[rules.arrReq]"
                >
                </v-select>
              </v-col>
            </v-row> -->
          </v-container>
        </v-card-text>

        <v-card-actions class="d-flex justify-center">
          <v-btn class="okButton white--text" height="45" type="submit">
            Dodaj
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>

import rules from '@/plugins/rules'
import MoneyInput from '@/components/MoneyInput'
import { clone } from '@/plugins/utils'
import state from '@/state'
export default {
  components: { MoneyInput },
  name: 'AddCategory',
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  props: ['taxes', 'isManual'],
  data: () => ({
    visible: false,
    price: undefined,
    base: undefined,
    currency: undefined,
    currencies: ['HRK', 'EUR', 'USD', 'GBP'],
    rules: {
      req: rules.req(),
      money: rules.money(),
      arrReq: rules.arrReq()
    },
    pdv: {},
    selectedTaxes: [],
    messages: [],
    priceFieldDisabled: false,
    priceTaxes: [],
    item: undefined,
    itemId: undefined
  }),
  computed: {
    baseInput () {
      return this.base
    },
    isTaxed () {
      return state.getCurrentCompany().is_taxed
    }
  },
  watch: {
    isManual (val) {
      this.priceFieldDisabled = val
    }
  },
  methods: {
    open (existing = null, item = undefined) {
      this.visible = true

      if (item !== undefined) {
        this.item = item
      }
      if (existing !== null && existing.itemId !== undefined) {
        this.itemId = existing.itemId
      }

      this.$nextTick(() => {
        this.priceTaxes = clone(this.taxes)
        if (existing) {
          if (existing.item !== undefined && existing.item.selectedPrices !== undefined) {
            delete existing.item.selectedPrices
          }
          const _existing = clone(existing)
          this.currency = _existing.currency
          this.price = _existing.price.toString()
          if (!this.isTaxed) {
            this.priceTaxes = clone(this.taxes.map(tax => { return { ...tax, ...{ disabled: tax.rate !== 0 } } }))
          }
          this.pdv = _existing.taxes.map(tax => { return { name: tax.name, rate: parseInt(tax.rate) } })[0]
        } else {
          this.resetValues()
          if (!this.isTaxed) {
            this.priceTaxes = clone(this.taxes.map(tax => { return { ...tax, ...{ disabled: tax.rate !== 0 } } }))
            this.pdv = this.taxes.find(tax => tax.rate === 0)
          } else {
            this.pdv = this.taxes.find(tax => tax.rate === 2500)
          }
        }
        this.calculateBase()
        this.price = this.$n(this.price, 'currency', this.currency)
        this.$refs.priceInput.$el.getElementsByTagName('input')[0].value = this.price
      })
    },
    close () {
      this.$emit('close')
      this.visible = false
    },
    calculateBase () {
      const price = this.$refs.priceInput.currencyToFloat(this.price)
      const base = parseFloat(price / (1 + this.pdv.rate / 10000)).toFixed(2)
      this.base = this.$n(base, 'currency', this.currency)
      this.$refs.baseInput.$el.getElementsByTagName('input')[0].value = base
    },
    calculatePrice () {
      const base = this.$refs.baseInput.currencyToFloat(this.base)
      const price = parseFloat(base + this.pdv.rate / 10000 * base).toFixed(2)

      this.$refs.priceInput.$el.getElementsByTagName('input')[0].value = price
    },
    submit () {
      if (!this.$refs.addPriceForm.validate()) return
      const taxes = [this.pdv].map(tax => {
        return { name: tax.name, rate: tax.rate }
      })
      const price = this.$refs.priceInput.currencyToFloat(this.price)
      this.$emit('add', { price: price, taxes: taxes, currency: this.currency, item: this.item, itemId: this.itemId })

      this.resetValues()
      this.visible = false
    },
    resetValues () {
      this.price = 0
      this.base = 0
      this.currency = 'HRK'
      if (Date.now() / 1000 > 1672527599) {
        this.currency = 'EUR'
      }
      this.selectedTaxes = []
    }
  }
}
</script>
