<template>
  <v-dialog
    v-model="dialog"
    :scrollable="scrollable"
    @keyup.esc.stop="cancel"
    @keydown.enter="agree()"
    :max-width="width"
    style="z-index: 7"
    tabindex="1"
  >
    <v-form ref="addCashRegisterForm" @submit.prevent="submit">
      <v-card class="overflow-auto text-center">
        <v-card-title class="d-flex" style="vertical-align: middle">
        </v-card-title>
        <v-card-text>
          <div
            class="row"
            v-for="rowIdx in Math.ceil(users.length / 4)"
            :key="rowIdx"
          >
            <div
              class="one-fourth column"
              v-for="user in users.slice(4 * (rowIdx - 1), 4 * rowIdx)"
              :key="user.id"
            >
              <div class="pl-5 pb-3 text-center">
                <v-btn
                  :color="stringToHslColor(user.name)"
                  fab
                  x-large
                  dark
                  @click="userChoosed(user)"
                >
                  <v-icon>mdi-account-circle</v-icon>
                </v-btn>
                <div
                  style="width: 100px"
                  class="text-capitalize text-center pt-1 font-weight-bold"
                >
                  {{ user.name }} {{ user.surname }}
                </div>
              </div>
            </div>
          </div>
          <v-row> </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import rules from '@/plugins/rules'
import { clone } from '@/plugins/utils'

export default {
  props: ['toolbar', 'confirmDisabled'],
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    selectedUser: '',
    width: '550px',
    users: [],
    scrollable: false,
    confirmDis: true,
    cashReg: {},
    rules: {
      req: rules.req()
    }
  }),
  methods: {
    stringToHslColor (str, s, l) {
      var hash = 0
      s = 30
      l = 50
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
      }

      var h = hash % 360
      return 'hsl(' + h + ', ' + s + '%, ' + l + '%)'
    },
    open (users, cashReg) {
      const usersUnsorted = []
      users.forEach(user => {
        if (user && user.user && user.user.id && user.user.id !== 'Zg4qIpxX2uNGKvjznSVnICZAVtJ2' && 'PRODAVAČ' in user.user.roles) {
          usersUnsorted.push(user.user)
        }
      })
      this.users = clone(usersUnsorted.sort((a, b) => a.name.localeCompare(b.name)))
      this.cashReg = cashReg
      this.dialog = true
    },
    close () {
      this.dialog = false
    },
    userChoosed (selectedUser) {
      this.$emit('userSelectSuccess', { user: selectedUser, cashReg: this.cashReg })
      this.dialog = false
    }
  },
  computed: {
    nrows () {
      return Math.floor((this.users.length - 1) / 3) + 1
    }
  }
}
</script>
