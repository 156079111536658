<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    scrollable
    max-width="40%"
    max-height="100%"
    style="z-index: 6"
  >
    <v-form ref="addProductToMenuForm" @submit.prevent="submit">
      <v-card
        class="overflow-auto pb-5"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="mb-2">
            <v-col class="d-flex justify-start">
              <h2 class="text--secondary">Stavka</h2>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex justify-end">
              <v-icon large @click="close">mdi-close</v-icon>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="10" class="d-flex justify-start">
                <v-select
                  :items="productTypes"
                  v-model="productType"
                  dense
                  outlined
                  return-object
                  item-text="name"
                  label="Vrsta stavke"
                  autofocus
                  :rules="[rules.req]"
                ></v-select>
              </v-col>
              <v-col cols="2" class="d-flex justify-end">
                <v-btn
                  v-if="!addButtonHidden"
                  outlined
                  class="white--text okButton"
                  height="40"
                  @keyup.enter="addItem"
                  @click.stop="addItem"
                  >Dodaj</v-btn
                >
              </v-col>
            </v-row>
            <v-card outlined v-if="product !== undefined && product !== null">
              <v-card-text>
                <v-row>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field
                      dense
                      outlined
                      class="compact-form"
                      label="Naziv stavke"
                      v-model="product.productName"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row v-for="(item, itKey) in product.items" :key="itKey">
                  <v-row class="pa-4 pb-0">
                    <v-col
                      cols="7"
                      style="padding: 0"
                      class="d-flex justify-start"
                    >
                      <v-text-field
                        class="compact-form"
                        outlined
                        dense
                        label="Sastojak"
                        disabled
                        :value="item.name"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="3"
                      style="padding: 0"
                      class="d-flex justify-end"
                    >
                      <v-checkbox
                        v-model="item.default"
                        style="margin-top: 14px"
                        class="compact-form"
                        outlined
                        @change="changeDefault(item)"
                        label="Zadana stavka"
                      ></v-checkbox>
                    </v-col>
                    <v-col
                      cols="2"
                      style="padding: 0"
                      class="d-flex justify-end"
                    >
                      <v-btn
                        style="margin: 10px 6px 0 0; height: 40px"
                        plain
                        outlined
                        dense
                        class="compact-form"
                        @click="removeItemFromProduct(item)"
                        >Ukloni</v-btn
                      >
                    </v-col>
                  </v-row>

                  <v-row class="pa-2 pt-0">
                    <v-col class="pt-0 pb-1">
                      <v-row class="pb-0 pt-0">
                        <v-col class="pt-2 pl-5" cols="12">
                          <div>Ustrojstvene jedinice prema lokaciji</div>
                        </v-col>
                      </v-row>
                      <v-row class="pt-0">
                        <v-col
                          class="pt-0 pl-5"
                          v-for="(location, locKey) in locations"
                          :key="locKey"
                        >
                          <div>{{ location.name }}</div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row class="pa-2 pt-0">
                    <v-col class="pt-0">
                      <v-row>
                        <v-col
                          class="pt-0 pl-5"
                          v-for="(location, locKey) in locations"
                          :key="locKey"
                        >
                          <v-select
                            :items="location.orgUnits"
                            lablel="Ustrojstvena jedinica"
                            item-text="name"
                            item-value="id"
                            v-model="
                              product.items[itKey].org_units[location.id]
                            "
                            outlined
                            dense
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-row>

                <v-row>
                  <v-col cols="10" style="padding: 0">
                    <v-autocomplete
                      dense
                      outlined
                      class="compact-form"
                      label="Artikl"
                      v-model="product.itemSelected"
                      item-text="name"
                      return-object
                      :items="items"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="2" style="padding: 0">
                    <v-btn
                      dense
                      height="40px"
                      :disabled="!product.productName || !product.itemSelected"
                      class="white--text okButton compact-form"
                      @keyup.enter="addItemToProduct(product.itemSelected)"
                      @click.stop="addItemToProduct(product.itemSelected)"
                      >Dodaj</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting"
            type="submit"
            >Spremi</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { df } from '@/plugins/firebase'
import state from '@/state'
import rules from '@/plugins/rules'
import { clone } from '@/plugins/utils'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'AddProductToMenu',
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  data: () => ({
    visible: false,
    name: undefined,
    existing: false,
    submitting: false,
    rules: {
      req: rules.req('Required field')
    },
    items: [],
    itemSelected: undefined,
    productItems: [],
    productType: { name: 'Višestruki odabir - obavezan', value: 'manC' },
    product: undefined,
    edit: false,
    addButtonHidden: false,
    selectedLocations: []
  }),
  computed: {
    productTypes () {
      return [
        { name: 'Višestruki odabir - obavezan', value: 'manC' },
        { name: 'Višestruki odabir - opcionalan', value: 'optC' },
        { name: 'Jednostruki odabir - obavezan', value: 'manR' },
        { name: 'Jednostruki odabir - opcionalan', value: 'optR' }
      ]
    }
  },
  watch: {
    product: {
      immediate: false,
      deep: true,
      handler () {
        this.addButtonHidden = true
      }
    }
  },
  methods: {
    changeDefault (item) {
      if (this.productType.value === 'manC' || this.productType.value === 'optC') {
        return
      }
      if (this.product.items !== undefined && this.product.items !== null && this.product.items.length > 0) {
        const prod = this.product.items.find(a => a.id === item.id)
        this.product.items.forEach((i) => {
          i.default = i.id === prod.id
        })
        this.$forceUpdate()
      }
    },
    addItemToProduct (itemSelected) {
      if (this.product.items === undefined || this.product.items === null) {
        this.product.items = []
      }

      itemSelected.default = false
      itemSelected.org_units = {}

      this.product.items.push(itemSelected)
      this.product.itemSelected = undefined
    },
    removeItemFromProduct (itemSelected) {
      this.product.items = this.product.items.filter(item => {
        if (item.id !== itemSelected.id) {
          return item
        }
      })

      const temp = this.product.items
      this.product.items = []
      this.product.items = temp

      this.$forceUpdate()
    },
    removeItem (rmItem) {
      this.items = this.items.filter(item => {
        if (item.id !== rmItem.id) {
          return item
        }
      })
    },
    addItem () {
      const newProduct = {
        id: uuidv4(),
        productType: this.productType
      }
      this.product = newProduct
    },
    findType (type) {
      switch (type) {
        case 'manC':
          return 'Višestruki odabir - obavezan'
        case 'optC':
          return 'Višestruki odabir - opcionalan'
        case 'manR':
          return 'Jednostruki odabir - obavezan'
        case 'optR':
          return 'Jednostruki odabir - opcionalan'
      }
    },
    async open (existing = undefined, edit = false, selectedLocations = undefined, locations = undefined) {
      this.addButtonHidden = false
      this.edit = edit
      if (selectedLocations) {
        this.selectedLocations = clone(selectedLocations)
      }
      if (locations) {
        this.locations = clone(locations)

        if (selectedLocations && selectedLocations.length > 0) {
          const selectedLocs = []

          this.locations.forEach(location => {
            selectedLocations.forEach(loc => {
              if (location.id === loc) {
                df.collection(`location_units/${location.id}/units`)
                  .get()
                  .then((documentSnapshots) => {
                    if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
                      this.orgUnits = []
                      documentSnapshots.docs.forEach((orgUnit) => {
                        this.orgUnits.push(orgUnit.data())
                      })
                    }
                    location.orgUnits = this.orgUnits
                  })

                selectedLocs.push(location)
              }
            })
          })

          this.locations = selectedLocs
        }
      }

      const unsortedItems = []

      const query = await df
        .collection(`companies/${state.getCurrentCompany().id}/items`)
        .where('status', '==', 'OK')

      query.get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            const item = doc.data()
            if (item.warehouse_units !== undefined && item.warehouse_units.units !== undefined) {
              Object.keys(item.warehouse_units.units).forEach(key => {
                const newItem = clone(item)
                newItem.name = item.warehouse_units.units[key].storage_unit
                newItem.prices = item.warehouse_units.units[key].prices

                var stUnit = item.warehouse_units.units[key].storage_unit
                if (stUnit === '.kom') {
                  stUnit = ''
                }

                newItem.name = item.name + ' ' + stUnit
                // if (item.warehouse_units.units[key].storage_unit && item.warehouse_units.units[key].storage_unit !== '') {
                //   newItem.name = item.name + ' ' + item.warehouse_units.units[key].storage_unit
                // } else {
                //   newItem.name = item.name
                // }
                newItem.id = item.id + '.' + item.warehouse_units.units[key].id

                unsortedItems.push(newItem)
              })
            } else {
              unsortedItems.push(item)
            }
          })
          this.items = clone(unsortedItems.sort((a, b) => a.name.localeCompare(b.name)))
        })

      this.visible = true

      if (existing !== undefined && !edit) {
        this.existing = true
        this.product = existing
      }

      // Transformacija za edit - NE DIRATI
      if (existing !== undefined && edit) {
        this.existing = true
        this.edit = true

        const productType = {
          name: this.findType(existing.type),
          value: existing.type
        }

        this.product = {
          id: existing.id,
          productName: existing.name,
          productType: productType
        }

        if (existing.product_items !== undefined && existing.product_items.length > 0) {
          this.product.items = []
          existing.product_items.forEach(pi => {
            pi.company_item.default = pi.default
            if (!pi.company_item.org_units) {
              pi.company_item.org_units = {}
            }
            this.product.items.push(pi.company_item)
          })
        }
      }
    },
    close () {
      this.$emit('close')
      this.visible = false
      this.product = undefined
      this.itemSelected = undefined
    },
    submit () {
      const that = this
      if (!this.$refs.addProductToMenuForm.validate()) return

      let realProduct = this.product
      if (this.edit) {
        realProduct = {
          id: this.product.id,
          name: this.product.productName,
          type: this.product.productType.value
        }

        if (this.product.items !== undefined && this.product.items.length > 0) {
          realProduct.product_items = []

          this.product.items.forEach(item => {
            realProduct.product_items.push({
              company_item: item,
              default: item.default
            })
          })
        }
      }

      that.$emit('add', realProduct, this.existing)
      this.close()
    }
  }
}
</script>
<style scoped>
.compact-form {
  margin: 10px 10px -16px 10px;
}
</style>
