<template>
  <div fluid class="fill-height">
    <v-btn
      style="z-index: 4; bottom: 60px"
      rounded
      fixed
      bottom
      right
      color="primary"
      @click="openAddRegisterForm"
      v-if="$vuetify.breakpoint.smAndDown"
    >
      <v-icon>mdi-plus</v-icon>
      <span>{{ $t(`$vuetify.pointOfSale.addRegisterBtn`) }}</span>
    </v-btn>
    <v-container
      v-if="!registers || registers.length === 0"
      class="d-flex fill-height justify-center"
      style="height: 800px"
    >
      <div>
        Još nemate niti jednu dodanu blagajnu.
        <a href="" @click.prevent="openAddRegisterForm">Dodaj blagajnu</a>
      </div>
    </v-container>
    <v-container
      v-else
      fluid
      class="d-flex justify-center align-start flex-wrap fill-height"
      style="position: relative"
    >
      <v-row>
        <v-spacer></v-spacer>
        <Search-box
          :emitDestination="searchEmitDestination"
          searchPlaceholder="Pretraži blagajne"
        ></Search-box>
      </v-row>
      <v-row
        class="d-flex justify-center align-center fill-height"
        style="position: absolute"
      >
        <v-card
          class="align-center justify-self-center ma-5 mt-0"
          v-for="(register, i) in currentRegisters"
          :key="i"
          hover
          height="300"
          width="250"
          @click="openRegister(register)"
          @keypress.enter.prevent="openRegister(register)"
          :ref="`register${i}`"
        >
          <v-img
            height="190"
            width="250"
            contain
            src="@/assets/cash_register.png"
          >
          </v-img>
          <div class="mr-4 ml-4">
            <div>
              <h3 class="one-liner-text">{{ register.name }}</h3>
              <p class="grey--text mb-1 one-liner-text">
                Status: {{ register.status }}
              </p>
              <!-- <p class="grey--text mb-1 one-liner-text">
                Blagajna zauzeo: {{ register.reserved_by !== undefined ? register.reserved_by : '' }}
              </p> -->
            </div>
          </div>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-menu offset-y nudge-left="145" v-if="!hideMenu">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @keypress.enter.stop="">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  v-for="(item, index) in registerOptions"
                  :key="index"
                  style="cursor: pointer"
                  @click="`${item.action(register)}`"
                  @keypress.enter.prevent="`${item.action(register)}`"
                  :disabled="register.status === 'PROCESSING'"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-actions>
        </v-card>
      </v-row>
    </v-container>
    <add-cash-register
      ref="cashRegisterForm"
      @success="refreshRegisters"
    ></add-cash-register>
    <edit-cash-register
      ref="editCashRegisterForm"
      @editSuccess="refreshEditedRegister"
    ></edit-cash-register>
    <edit-register-receipt-settings
      ref="editRegisterReceiptSettings"
      @editSuccess="refreshEditedRegister"
    ></edit-register-receipt-settings>
    <user-selection
      ref="userSelection"
      @userSelectSuccess="userSelected"
    ></user-selection>
    <pin-login ref="pinLogin" @pinLoginSuccess="userSelected"></pin-login>
    <education-mode ref="educationMode"></education-mode>
  </div>
</template>
<script>

import { auth, df } from '@/plugins/firebase'
import { clone } from '@/plugins/utils'
import AddCashRegister from '@/modules/point-of-sale/components/AddCashRegister'
import EditCashRegister from '@/modules/point-of-sale/components/EditCashRegister'
import EditRegisterReceiptSettings from '@/modules/point-of-sale/components/EditCashRegisterReceiptSettings'
import UserSelection from '@/modules/point-of-sale/components/UserSelection'
import PinLogin from '@/modules/point-of-sale/components/PinLogin'
import EducationMode from '@/modules/point-of-sale/components/EducationMode'
import EventBus from '@/plugins/event-bus'
import state from '@/state'
import applicationSettings from '@/mixins/applicationSettings'
import LogData from '@/model/LogData'
import log from '@/mixins/log'

export default {
  components: { AddCashRegister, EditCashRegister, EditRegisterReceiptSettings, UserSelection, PinLogin, EducationMode },
  inject: ['showMsgBox', 'confirm', 'confirmClose', 'showLoader', 'hideLoader', 'showMsgBoxHtml'],
  data: vm => ({
    registers: [],
    currentRegisters: [],
    cashRegUsers: [],
    listeners: [],
    hideMenu: false,
    registerIds: [],
    registerOptions: [
      { title: 'Postavke blagajne', action: vm.openEditRegisterForm },
      { title: 'Postavke računa blagajne', action: vm.editRegisterReceiptSettings }
      // { title: 'Postavke edukacijskog moda', action: vm.educationMode }
    ],
    searchEmitDestination: 'searchCashRegisters'
  }),
  mixins: [applicationSettings, log],
  computed: {
    search () {
      return state.filter
    }
  },
  watch: {
    registers: {
      handler (nv, ov) {
        if (nv !== ov) {
          let currentRegisters

          if (this.search && this.search !== '') {
            currentRegisters = nv.filter(register => register.name.toLowerCase().includes(this.search.toLowerCase()))
          } else {
            currentRegisters = nv
          }
          this.currentRegisters = clone(currentRegisters.sort((a, b) => a.name.localeCompare(b.name)))
        }
      },
      deep: true,
      immediate: true
    }
    // search (val) {
    //   let currentRegisters
    //   if (val !== '') {
    //     currentRegisters = this.registers.filter(pSale => pSale.name.toLowerCase().includes(this.search.toLowerCase()))
    //   } else {
    //     currentRegisters = this.registers
    //   }
    //   this.currentRegisters = clone(currentRegisters.sort((a, b) => a.name.localeCompare(b.name)))
    // }
  },
  created () {
    const docRef = df.doc(`user_cash_registers/${auth.currentUser.uid}.${state.getCurrentCompany().id}.${state.getPointOfSale().id}`)
    const listener = docRef.onSnapshot((doc) => {
      const data = doc.data()
      if (data?.cash_registers) {
        this.registers = Object.keys(data.cash_registers)
          .map(key => {
            return data.cash_registers[key]
          })
          .filter(register => register?.resource_status === 'ACTIVE')
          .sort((register1, register2) => {
            if (register1.id < register2.id) return -1
            if (register1.id > register2.id) return 1
          })
        if (this.registers?.length > 0) {
          state.registerIds = this.registers.map(register => { return register.number })
        } else {
          state.registerIds = []
        }
      } else {
        state.registerIds = []
      }
    })
    this.listeners.push(listener)
  },
  mounted () {
    EventBus.$on(this.searchEmitDestination, (searchObject) => {
      this.searchAll(searchObject.searchString)
    })
    const user = state.getUser()

    if (!user.roles.ADMIN && !user.roles.SUPERADMIN) {
      this.hideMenu = true
    }
    try {
      this.$refs.register0[0].$el.focus()
    } catch {
      setTimeout(() => {
        if (this.$refs.register0 && this.$refs.register0[0]) {
          this.$refs.register0[0].$el.focus()
        }
      }, 500)
    }
  },
  methods: {
    searchAll (val) {
      let currentRegisters
      if (val !== '') {
        currentRegisters = this.registers.filter(pSale => pSale.name.toLowerCase().includes(val.toLowerCase()))
      } else {
        currentRegisters = this.registers
      }
      this.currentRegisters = clone(currentRegisters.sort((a, b) => a.name.localeCompare(b.name)))
    },
    userSelected (data) {
      data.cashReg.reserving = data.user.name + ' ' + data.user.surname
      state.setCashRegisterUser(data.user)

      this.callReservation(data.cashReg, false)
    },
    openUserSelection (users, cashReg) {
      this.$refs.userSelection.open(users, cashReg)
    },
    openPincodeLogin (users, cashReg) {
      this.$refs.pinLogin.open(users, cashReg)
    },
    async getCashRegister (id) {
      const register = await df.doc(`cash_registers/${id}`).get()
      return register
    },
    async getUser () {
      const user = await df.doc(`users/${auth.currentUser.uid}`).get()
      return user
    },
    async getCashRegUsers (id) {
      const query = await df.collection(`cash_registers/${id}/users`)
      var cashRegUsers = []
      query.get().then((snapshot) => {
        snapshot.forEach((doc) => {
          cashRegUsers.push(doc.data())
        })
        return cashRegUsers
      })
    },
    openRegister (register) {
      if (register.status !== 'OK') {
        return
      }

      this.getCashRegister(register.id).then(async (regResp) => {
        const cashReg = regResp.data()

        if (cashReg.login_type === undefined || cashReg.login_type === '' || cashReg.login_type === 'DEFAULT') {
          this.getUser().then((userResp) => {
            const user = userResp.data()
            if (!('PRODAVAČ' in user.roles)) {
              this.showMsgBoxHtml({ text: 'Nemate privilegiju prodavača na ovom prodajnom mjestu, te niste u mogućnosti izdavati račune.<br/>Da biste dodali privilegiju prodavača odaberite<br/> Postavke → Korisnici te za korisnika odaberite privilegiju “PRODAVAČ”.', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
              return
            }
            cashReg.reserving = ''
            cashReg.reserving = user.name + ' ' + user.surname

            if (cashReg.reserved_by !== undefined && cashReg.reserved_by !== '') {
              if (cashReg.reserved_by !== user.name + ' ' + user.surname) {
                this.confirm({
                  title: 'Rezervacija blagajne',
                  message: `Trenutno je na blagajnu prijavljen ${
                   cashReg.reserved_by
                  }.<br>Da li se želite odjaviti tog korisnika i prijaviti sebe?`,
                  options: {
                    toolbar: true,
                    width: 410,
                    confirmText: 'DA',
                    cancelText: this.$t('$vuetify.close')
                  }
                }).then(async (resp) => {
                  if (resp) {
                    state.setCashRegisterUser(user)
                    this.callReservation(cashReg, true)
                  } else {
                    this.confirmClose()
                  }
                })
              } else {
                state.setCashRegisterUser(user)
                if (state.getCurrentCompany().save_log) {
                  this.prepareLogCall()
                }
                state.setCashRegister(cashReg)
                this.$router.push({
                  name: 'cashRegister',
                  params: {
                    companyId: state.getCurrentCompany().id,
                    locationId: state.getPointOfSale().id,
                    registerId: state.getCashRegister().id
                  }
                })
              }
            } else if (cashReg.reserved_by === undefined || cashReg.reserved_by === '') {
              state.setCashRegisterUser(user)
              this.callReservation(cashReg, false)
            }
          })
        } else if (cashReg.login_type === 'SELECT_USER') {
          const snapshot = await df.collection(`cash_registers/${cashReg.id}/users`).get()
          const cashRegUsers = []

          snapshot.forEach((doc) => {
            cashRegUsers.push(doc.data())
          })

          if (cashRegUsers.length === 1 && cashRegUsers[0].user.id === auth.currentUser.uid) {
            state.setCashRegisterUser(cashRegUsers[0].user)
            cashReg.reserving = cashRegUsers[0].user.name + ' ' + cashRegUsers[0].user.surname
            this.callReservation(cashReg, false)
          } else {
            this.openUserSelection(cashRegUsers, cashReg)
          }
        } else if (cashReg.login_type === 'PINCODE') {
          const snapshot = await df.collection(`cash_registers/${cashReg.id}/users`).get()
          const cashRegUsers = []

          snapshot.forEach((doc) => {
            cashRegUsers.push(doc.data())
          })

          if (cashRegUsers.length === 1 && cashRegUsers[0].user.id === auth.currentUser.uid) {
            state.setCashRegisterUser(cashRegUsers[0].user)
            cashReg.reserving = cashRegUsers[0].user.name + ' ' + cashRegUsers[0].user.surname
            this.callReservation(cashReg, false)
          } else {
            this.openPincodeLogin(cashRegUsers, cashReg)
          }
        }
      })
    },
    callReservation (register, closeConfirm) {
      const that = this
      if (closeConfirm) {
        that.confirmClose()
      }

      this.showLoader()

      if (register.reserved_by !== undefined) {
        df.doc(`cash_registers/${register.id}`).update({ reserved_by: register.reserving })
          .then(() => {
          })
          .catch(() => {
            that.hideLoader()
            that.showMsgBox({ text: 'Pogreška prilikom zapisa podataka', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
          })
          .finally(() => {
            that.hideLoader()
            state.setCashRegister(register)
            that.$router.push({
              name: 'cashRegister',
              params: {
                companyId: state.getCurrentCompany().id,
                locationId: state.getPointOfSale().id,
                registerId: state.getCashRegister().id
              }
            })
          })
      }

      if (state.getCurrentCompany().save_log) {
        this.prepareLogCall()
      }
    },
    prepareLogCall () {
      var req = new LogData()

      let userId = auth.currentUser.uid
      if (state.getCashRegisterUser().id && state.getCashRegisterUser().id !== '') {
        userId = state.getCashRegisterUser().id
      }

      req.action_name = 'Prijava'
      req.action_id = '1'
      req.user_id = userId

      this.setLog(req)
    },
    openAddRegisterForm () {
      this.$refs.cashRegisterForm.open()
    },
    openEditRegisterForm (register) {
      this.$refs.editCashRegisterForm.open(register)
    },
    editRegisterReceiptSettings (register) {
      this.$refs.editRegisterReceiptSettings.open(register)
    },
    educationMode (register) {
      this.$refs.educationMode.open(register)
    },
    refreshRegisters (register) {
      // this.registers.push({ name: register.name, status: 'PROCESSING' })
      this.currentRegisters.push({ name: register.name, status: 'PROCESSING' })
      this.currentRegisters = this.currentRegisters.sort((a, b) => a.name.localeCompare(b.name))
    },
    refreshEditedRegister (register) {
      this.currentRegisters = this.currentRegisters.map(reg => {
        if (reg.id === register.id) {
          reg.name = register.name
          reg.status = 'PROCESSING'
        }
        return reg
      })
    }
  }
}
</script>

<style scoped>
.one-liner-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
</style>
