<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    scrollable
    style="z-index: 6"
    max-width="430"
    min-width="430"
  >
    <v-form ref="priceListForm" @submit.prevent="submit">
      <v-card
        class="overflow-auto pb-5"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h2 class="text--secondary">Artikl</h2>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                class="d-flex justify-start align-center pb-0"
              >
                <v-select
                  label="Artikl sa skladišta"
                  bottom
                  outlined
                  dense
                  v-model="selectedItem"
                  :items="warehouseItems"
                  return-object
                  item-text="name"
                  :menu-props="{ bottom: true }"
                  hide-selected
                  :rules="[rules.req]"
                  id="itemSelection"
                  autofocus
                  @input="setFocusOnNext"
                >
                  <template v-slot:prepend-item>
                    <v-text-field
                      autofocus
                      class="pr-5 pl-5"
                      label="Pretraži skladište"
                      @click:prepend-inner="getWarehouseItems"
                      prepend-inner-icon="mdi-magnify"
                      v-model="searchModel"
                      @keyup.enter="getWarehouseItems"
                      :loading="loading"
                      id="searchItem"
                    >
                    </v-text-field>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:item="{ item }">
                    {{ item.name }}, Stock: {{ item.stock }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0 pt-0">
                <v-checkbox
                  v-model="manualPriceInput"
                  label="Upis cijene na blagajni"
                  @change="setCurrency()"
                >
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row v-if="!warehouseModule">
              <v-col v-if="!warehouseModule" cols="12" class="pb-0">
                <v-select
                  v-if="!warehouseModule"
                  multiple
                  outlined
                  dense
                  clearable
                  label="Odaberi skladišta"
                  :items="locations"
                  id="locations"
                  item-text="name"
                  item-value="id"
                  persistent-hint
                  v-model="selectedLocations"
                  :rules="[rules.arrReq]"
                  ref="warehouses"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="7" class="pb-0">
                <v-select
                  multiple
                  outlined
                  dense
                  label="Odaberite kategoriju"
                  :items="categories"
                  return-object
                  item-text="name"
                  v-model="selectedCategories"
                  :rules="[rules.arrReq]"
                  :loading="categoryProcessing"
                ></v-select>
              </v-col>
              <v-col cols="5">
                <v-btn
                  x-small
                  class="mt-2"
                  style="z-index: 4"
                  bottom
                  right
                  plain
                  text
                  @click="openAddCategoryForm"
                >
                  <v-icon>mdi-plus</v-icon>
                  <span>Dodaj kategoriju</span>
                </v-btn>
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col cols="12">
                <v-combobox
                  outlined
                  autocomplete="off"
                  v-model="selectedPrices"
                  :items="[]"
                  dense
                  label="Cijene za artikla u valutama"
                  multiple
                  chips
                  clearable
                  @click="openAddPriceForm"
                  @keydown.enter="openAddPriceForm"
                  @keydown="preventTextInput($event)"
                  :rules="[rules.arrReq]"
                >
                  <template v-slot:selection="{ item }">
                    <v-chip
                      class="mt-1 mr-0"
                      close
                      @click="editPrice(item)"
                      @click:close="removePrice(item)"
                    >
                      <div v-if="manualPriceInput">
                        {{ item.currency }}
                      </div>
                      <div v-else>
                        {{ item.price | money(1, item.currency) }}
                      </div>
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitDisabled()"
            type="submit"
            id="lastElement"
          >
            {{ $t("$vuetify.pointOfSale.addPricelistItemBtn") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <add-category
      ref="addCategory"
      @processing="setCategoryProcessing"
      @close="$refs.warehouses.focus()"
    ></add-category>
    <add-price
      ref="addPrice"
      :taxes="taxes"
      :isManual="manualPriceInput"
      @add="onAddPrice"
      @close="$refs.warehouses.focus()"
    ></add-price>
  </v-dialog>
</template>
<script>
import state from '@/state'
import { df, auth, functions } from '@/plugins/firebase'
import rules from '@/plugins/rules'
// import { clone } from '@/plugins/utils'
import AddCategory from '@/modules/company/components/AddCategory'
import AddPrice from '@/modules/point-of-sale/components/AddPrice'
import EventBus from '@/plugins/event-bus'
import { clone } from '@/plugins/utils'

export default {
  components: { AddCategory, AddPrice },
  inject: ['showMsgBox', 'showLoader', 'hideLoader', 'showAlert'],
  data: () => ({
    visible: false,
    submitting: false,
    loading: false,
    categoryProcessing: false,
    selectedItem: undefined,
    searchModel: '',

    item: undefined,
    warehouseItems: [],

    selectedCategories: [],
    categories: [],

    selectedTaxes: [],
    taxes: [],

    selectedLocations: [],
    locations: [],

    selectedPrices: [],
    previousPrices: [],

    itemSelection: false,
    selected: false,
    warehouse: undefined,

    rules: {
      req: rules.req(),
      arrReq: rules.arrReq(),
      money: rules.money()
    },
    manualPriceInput: false,
    listeners: [],
    warehouseModule: false
  }),

  watch: {
    // manualPriceInput (val) {
    //   if (val) {
    //     this.previousPrices = clone(this.selectedPrices)
    //     console.log(this.selectedPrices)
    //   } else {
    //     this.selectedPrices = clone(this.previousPrices)
    //   }
    // }
  },
  mounted () {
    this.getWarehouse()
    if (state.getCurrentCompany().warehouse === 'module') {
      this.warehouseModule = true
    }
    EventBus.$on('open-add-pointOfSale', () => this.open())
    this.setDefaultDateTime()

    document.onkeydown = this.handleKeyEvents
    const rule = (v) =>
      this.warehouseItems.includes(v) || 'Please select a warehouse item'

    this.rules = { ...this.rules, ...{ itemExists: rule } }
  },
  beforeDestroy () {
    if (this.listeners) {
      this.detachListeners()
    }
  },
  methods: {
    setCurrency () {
      if (this.manualPriceInput === true && this.selectedPrices && this.selectedPrices.length === 0) {
        if (state.getCurrentCompany().is_taxed) {
          this.selectedPrices = [{
            base: 0,
            currency: 'EUR',
            price: 0,
            taxes: [{
              name: 'PDV 25%',
              rate: 2500
            }]
          }]
        } else {
          this.selectedPrices = [{
            base: 0,
            currency: 'EUR',
            price: 0,
            taxes: [{
              name: 'PDV 0%',
              rate: 0
            }]
          }]
        }
      }
    },
    submitDisabled () {
      if (this.submitting === true || this.selectedPrices.length === 0) {
        return true
      } else {
        return false
      }
    },
    async getWarehouse () {
      let warehouse = {}

      const query = await df
        .collection('warehouses/')
        .where('company_id', '==', state.getCurrentCompany().id)

        .where('selling_warehouse', '==', true)
        .where('status', '==', 'OK')

      if (state.getPointOfSale().id) {
        query.where('location_id', '==', state.getPointOfSale().id)
      }

      query.get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            warehouse = doc.data()
          })
        })

      this.warehouse = warehouse
    },
    preventTextInput (e) {
      if (e.keyCode !== 9 && e.keyCode !== 27) {
        this.openAddPriceForm()
        e.preventDefault()
      }
    },
    handleKeyEvents (e) {
      e = e || window.event
      if (e.keyCode === 9) {
        if (document.activeElement.id.includes('lastElement')) {
          e.preventDefault()
          document.getElementById('itemSelection').focus()
        }
      }
    },
    setFocusOnNext () {
      document.getElementById('locations').focus()
    },
    open (preselectedItem = false) {
      this.visible = true
      this.submitting = false
      this.setDefaults()
      this.listeners.push(
        df
          .doc(`company_categories/${state.getCurrentCompany().id}`)
          .onSnapshot((doc) => {
            if (
              doc &&
              doc.data() &&
              doc.data().categories &&
              Object.keys(doc.data().categories).length > 0
            ) {
              const categories = Object.keys(doc.data().categories).map(
                (key) => {
                  return doc.data().categories[key]
                }
              )

              this.categories = clone(categories.sort((a, b) => a.name.localeCompare(b.name)))
              this.categoryProcessing = false
            }
          })
      )

      this.listeners.push(
        df.doc('project_constants/taxes').onSnapshot((doc) => {
          if (doc.data() && doc.data().pdv && doc.data().pdv.length > 0) {
            // this.$refs.addCashRegisterForm.reset()
            this.taxes = doc.data().pdv.map((tax) => {
              return {
                ...tax,
                ...{
                  disabled:
                    !state.getCurrentCompany().is_taxed && tax.rate !== 0
                }
              }
            })
          }
        })
      )
      this.listeners.push(
        df
          .doc(
            `user_locations/${auth.currentUser.uid}.${
              state.getCurrentCompany().id
            }`
          )
          .onSnapshot((doc) => {
            if (
              doc.data() &&
              doc.data().locations &&
              Object.keys(doc.data().locations).length > 0
            ) {
              this.locations = Object.keys(doc.data().locations)
                .map((key) => {
                  return doc.data().locations[key]
                })
                .filter((location) => location.status === 'OK')

              this.selectedLocations = this.locations.map((location) => {
                return location.id
              })
            }
          })
      )

      if (preselectedItem) {
        this.warehouseItems = [preselectedItem]
        this.selectedItem = preselectedItem
      }
    },
    close () {
      this.detachListeners()
      this.$nextTick(() => {
        this.selectedPrices = []
        this.$refs.priceListForm.reset()
        this.visible = false
      })
    },
    openAddCategoryForm () {
      this.$refs.addCategory.open()
    },
    openAddPriceForm () {
      this.$refs.addPrice.open()
    },
    onAddPrice (priceItem) {
      if (
        this.selectedPrices.some((item) => item.currency === priceItem.currency)
      ) {
        const sameItem = this.selectedPrices.find(
          (it) => it.currency === priceItem.currency
        )
        this.selectedPrices = this.selectedPrices.map((item) => {
          item.price = item === sameItem ? priceItem.price : item.price
          return item
        })
      } else {
        this.selectedPrices.push(priceItem)
      }
    },
    removePrice (priceItem) {
      this.selectedPrices = this.selectedPrices.filter(
        (item) => item !== priceItem
      )
    },
    editPrice (priceItem) {
      this.$refs.addPrice.open(priceItem)
    },
    setCategoryProcessing (processing) {
      if (processing) {
        this.categoryProcessing = true
      } else {
        this.categoryProcessing = false
      }
    },
    setDefaults () {
      this.dateFrom = new Date().toISOString().substr(0, 10)
      this.timeFrom = new Date().toLocaleTimeString('it-IT').substr(0, 5)
    },
    detachListeners () {
      this.listeners.forEach((listener) => {
        listener()
        this.listeners = this.listeners.filter((l) => l !== listener)
      })
    },
    submit () {
      if (!this.$refs.priceListForm.validate()) {
        return
      }
      const categories = this.selectedCategories.map((category) => {
        return {
          id: category.id,
          name: category.name,
          picture: category.picture
        }
      })
      try {
        this.submitting = true
        let prices = {}
        if (this.manualPriceInput) {
          prices = this.selectedPrices.reduce((prices, it) => {
            return Object.assign(prices, {
              [it.currency]: {
                active: true,
                price: 0,
                taxes: it.taxes.map((tax) => {
                  return { name: tax.name, rate: tax.rate }
                })
              }
            })
          }, {})
        } else {
          prices = this.selectedPrices.reduce((prices, it) => {
            return Object.assign(prices, {
              [it.currency]: {
                active: true,
                price: parseInt((it.price * 100).toFixed(2)),
                taxes: it.taxes.map((tax) => {
                  return { name: tax.name, rate: tax.rate }
                })
              }
            })
          }, {})
        }

        let manualPrice = false
        if (this.manualPriceInput !== undefined) {
          manualPrice = this.manualPriceInput
        }

        const payload = {
          action: {
            operation: 'set',
            entity: 'price_list',
            params: {
              warehouses: this.selectedLocations,
              company_id: state.getCurrentCompany().id,
              item_id: this.selectedItem.id,
              pricing_info: {
                active: true,
                categories: categories,
                prices: prices,
                manual_price_input: manualPrice
              }
            }
          }
        }

        var companyitemApi = functions.httpsCallable('companyitemapi')

        companyitemApi({
          action: {
            operation: payload.action.operation,
            entity: payload.action.entity,
            resource_id: payload.action.resource_id,
            params: payload.action.params
          }
        })
          .then((result) => {
            if (result.data.code === 200) {
              this.submitting = false
            } else {
              this.showMsgBox({
                text: result.data.message ?? 'Dogodila se pogreška.',
                actions: ['cancel'],
                cancelBtnText: 'OK',
                color: 'error'
              })
              this.submitting = false
            }
            this.$nextTick(() => {
              this.$refs.priceListForm.reset()
              this.selectedPrices = []
              this.visible = false
            })
          })
      } catch (err) {
        this.showMsgBox({
          text: err && err !== '' ? err : 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        this.submitting = false
      }
    },
    async getWarehouseItems () {
      this.warehouseItems = []
      this.loading = true
      if (!this.warehouseModule) {
        const where = this.searchModel ? this.searchModel.toLowerCase() : ''
        df.collection(`location_warehouse/${state.getPointOfSale().id}/items`)
          .where('name', '>=', where)
          .where('name', '<=', where + '\uf8ff')
          .where('status', '==', 'OK')
          .orderBy('name')
          .limit(15)
          .get()
          .then((documentSnapshots) => {
          // Get the last visible document

            this.loading = false
            if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
              documentSnapshots.docs.forEach((item) => {
                this.warehouseItems.push(item.data())
              })
            }
          })
      } else {
        const where = this.searchModel ? this.searchModel.toLowerCase() : ''
        df.collection(`warehouses/${this.warehouse.id}/extended_items`)
          .where('item.name', '>=', where)
          .where('item.name', '<=', where + '\uf8ff')
          .where('item.status', '==', 'OK')
          .orderBy('item.name')
          .limit(15)
          .get()
          .then((documentSnapshots) => {
          // Get the last visible document

            this.loading = false
            if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
              documentSnapshots.docs.forEach((item) => {
                this.warehouseItems.push(item.data().item)
              })
            }
          })
      }
    },
    setDefaultDateTime () {
      this.dateFrom = new Date().toISOString().substr(0, 10)
      this.timeFrom = new Date().toLocaleTimeString('it-IT').substr(0, 5)
    }
  }
}
</script>
<style>
.items {
  max-height: 300px;
  height: 300px;
  overflow-y: auto;
}

.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
