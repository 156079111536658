<template>
  <v-container>
    <v-dialog
      v-model="visible"
      @keydown.esc="close"
      persistent
      max-width="550px"
      style="z-index: 6"
      width="640"
    >
      <v-form ref="editEducationMode">
        <v-card class="overflow-auto">
          <v-card-title
            class="d-flex pa-3 pb-0 mb-0"
            style="vertical-align: middle"
          >
            <v-row class="pa-3 mb-2">
              <h3 class="text--secondary">Blagajna - edukacijski mod</h3>
              <v-spacer></v-spacer>
              <v-icon large @click="close">mdi-close</v-icon>
            </v-row>
          </v-card-title>
          <v-card-text class="pt-0 mt-0">
            <div v-if="!eduModeActive">
              <v-col c justify-center>
                <h3 class="pt-0">Edukacijski mod nije aktivan.</h3>
              </v-col>
              <v-col justify-center>
                <v-btn
                  class="white--text okButton"
                  height="45"
                  @click="activateEduMode()"
                >
                  Aktiviraj
                </v-btn>
              </v-col>
            </div>
            <div v-else>
              <v-row class="pl-3">
                <h3>Broj izdanih računa:&nbsp;</h3>
                <h3>{{ totalReceipts }}</h3>
              </v-row>
              <v-row class="pl-3">
                <h3>Iznos izdanih računa:&nbsp;</h3>
                <h3>{{ totalAmount | money(100, currency) }}</h3>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn
                    class="white--text okButton"
                    height="45"
                    @click="startDeactivateEduMode()"
                  >
                    Deaktiviraj
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-form>
      <education-deactivation ref="educationDeactivation" @success="close">
      </education-deactivation>
      <v-overlay v-if="loading" absolute z-index="10">
        <v-progress-circular
          indeterminate
          size="64"
          color="primary"
        ></v-progress-circular>
      </v-overlay>
    </v-dialog>
  </v-container>
</template>
<script>

import { df, auth } from '@/plugins/firebase'
import { v4 as uuidv4 } from 'uuid'
import state from '@/state'
import EducationDeactivation from '@/modules/point-of-sale/components/EducationDeactivation'

export default {
  components: { EducationDeactivation },
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  name: 'EducationMode',
  data: () => ({
    visible: false,
    error: {
      show: false,
      message: undefined
    },
    register: {},
    eduModeActive: false,
    totalAmount: 0,
    totalReceipts: 0,
    receipts: [],
    deleteMode: '',
    startDeactivate: false,
    selectedOption: undefined,
    numOfReceipts: 0,
    listeners: [],
    loading: false,
    currency: 'EUR'
  }),
  mounted () {
    this.currency = 'HRK'
    if (Date.now() / 1000 > 1672527599) {
      this.currency = 'EUR'
    }
  },
  computed: {
    deactivateOptions () {
      return [
        { name: 'Obriši sve račune', value: 'DELETE_ALL' },
        { name: 'Fiskaliziraj dio računa', value: 'FISCALIZE_PART' },
        { name: 'Fiskaliziraj sve račune', value: 'FISCALIZE_ALL' }
      ]
    },
    reservedIds () {
      return state.registerIds
    }
  },
  methods: {
    startDeactivateEduMode () {
      this.$refs.educationDeactivation.open(this.register, this.receipts)
    },
    activateEduMode () {
      const that = this
      this.loading = true

      try {
        const reqId = uuidv4()
        this.$forceUpdate()
        const payload = {
          action: {
            operation: 'set',
            entity: 'education',
            params: {
              company_id: state.getCurrentCompany().id,
              location_id: state.getPointOfSale().id,
              cash_register_id: this.register.id,
              educational_mode: true,
              last_receipt_num: this.register.bill_sequence
            }
          }
        }

        df.doc(`request/${reqId}`).set(
          {
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'education',
            payload: btoa(unescape(encodeURIComponent(JSON.stringify(payload))))
          }
        ).then((doc) => {
          setTimeout(() => {
            that.loading = false
          }, 4000)
        }).catch(function (error) {
          setTimeout(() => {
            that.loading = false
            that.showMsgBox({ text: error || 'Internal error', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
          }, 4000)
        }).finally(() => {
          setTimeout(() => {
            that.close()
          }, 4000)
        })
      } catch (error) {
        that.loading = false
        that.showMsgBox({ text: error || 'An error has occurred', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
      }
    },
    async open (register) {
      const that = this
      const resp = await df.doc(`cash_registers/${register.id}`).get()
      this.register = resp.data()

      this.eduModeActive = this.register.educational_mode ? this.register.educational_mode : false
      this.visible = true

      if (this.eduModeActive) {
        if (state.getCurrentCompany().id !== '') {
          const query = df.collection(`receipts/${state.getCurrentCompany().id}/receipts`)
            .where('designation.number', '>', this.register.last_receipt_num)
            .where('designation.number', '<', this.register.last_educational_num ? this.register.last_educational_num + 1 : this.register.last_receipt_num)
            .where('designation.register_id', '==', this.register.id)
            .orderBy('designation.number', 'desc')

          const listener = query
            .onSnapshot((doc) => {
              doc.docs.forEach((rec) => {
                that.receipts.push(rec.data())
                that.totalAmount += rec.data().total
                that.totalReceipts++
              })
            }
            )
          this.listeners.push(listener)
        }
      }
    },
    close () {
      this.$emit('close')
      this.visible = false
      this.receipts = []
      this.totalAmount = 0
      this.totalReceipts = 0
      this.numOfReceipts = 0
      this.eduModeActive = false
      this.$refs.editEducationMode.reset()
    }
  }
}
</script>
<style scoped>
