<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    scrollable
    style="z-index: 6"
    max-width="50%"
    min-width="50%"
  >
    <v-form ref="menuForm" @submit.prevent="submit">
      <v-card
        height="100vh"
        class="overflow-auto pb-5"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h2 class="text--secondary">Meni</h2>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row style="padding: 0 !important; margin: 0 !important">
              <v-col
                cols="4"
                class="d-flex justify-start"
                style="height: 44px !important"
              >
                <v-text-field
                  @click="showKbd"
                  data-layout="normal"
                  outlined
                  dense
                  label="Naziv menija"
                  v-model="menuName"
                ></v-text-field>
              </v-col>
              <v-col cols="5">
                <v-combobox
                  outlined
                  autocomplete="off"
                  v-model="selectedPrices"
                  :items="[]"
                  dense
                  label="Cijene za artikla u valutama"
                  multiple
                  chips
                  clearable
                  @click="openAddPriceForm"
                  @keydown.enter="openAddPriceForm"
                  @keydown="preventTextInput($event)"
                  :rules="[rules.arrReq]"
                >
                  <template v-slot:selection="{ item }">
                    <v-chip
                      class="mt-1 mr-0"
                      close
                      @click="editPrice(item)"
                      @click:close="removePrice(item)"
                    >
                      <div v-if="manualPriceInput">
                        {{ item.currency }}
                      </div>
                      <div v-else>
                        {{ item.price | money(1, item.currency) }}
                      </div>
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>
              <v-col class="d-flex justify-end">
                <v-checkbox
                  class="pa-0 ma-0 pt-2"
                  v-model="manualPriceInput"
                  label="Bez unosa cijene"
                >
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row style="padding: 0 !important; margin: 0 !important">
              <v-col cols="6" class="d-flex justify-start">
                <v-select
                  multiple
                  outlined
                  dense
                  clearable
                  label="Odaberi prodajna mjesta"
                  :items="locations"
                  id="locations"
                  item-text="name"
                  item-value="id"
                  persistent-hint
                  v-model="selectedLocations"
                  :rules="[rules.arrReq]"
                  ref="warehouses"
                ></v-select>
              </v-col>
            </v-row>

            <v-expansion-panels flat>
              <v-expansion-panel
                style="
                  border: 1px solid #e4e4e4;
                  border-radius: 5px 5px 0 0;
                  margin-bottom: -1px;
                "
              >
                <v-expansion-panel-header>
                  <v-row>
                    <v-col class="d-flex justify-center">
                      <p class="text-h6 font-weight-bold mb-0">Stavke</p>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-data-table
                    :headers="productHeaders"
                    :items="productTableRows"
                    :single-expand="true"
                    :expanded.sync="expandedProducts"
                    item-key="name"
                    show-expand
                  >
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-toolbar-title>Popis stavki</v-toolbar-title>
                        <v-btn
                          class="mx-2 white--text okButton"
                          style="
                            border-color: #e4e4e4;
                            margin-top: 0.2rem;
                            height: 1.6rem;
                          "
                          outlined
                          small
                          @click="openAddProductToMenuForm()"
                          >Dodaj stavku</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-toolbar>
                    </template>
                    <template v-slot:item="{ item, expand, isExpanded }">
                      <tr>
                        <td>{{ item.productName }}</td>
                        <td>{{ item.productType.name }}</td>
                        <td>
                          <v-btn icon @click="openAddProductToMenuForm(item)">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </td>
                        <td>
                          <v-btn icon @click="removeProductFromMenu(item)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </td>
                        <td>
                          <v-btn icon @click="expand(!isExpanded)">
                            <v-icon>{{
                              isExpanded ? "mdi-chevron-up" : "mdi-chevron-down"
                            }}</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                    <template v-slot:expanded-item="{ item }">
                      <tr>
                        <td>Ime artikla</td>
                        <td>Šifra artikla</td>
                        <td>Zadani artikl</td>
                      </tr>
                      <tr
                        v-for="(it, index) in item.items"
                        :key="item.id + it.id + index"
                      >
                        <td>{{ it.name }}</td>
                        <td>{{ it.code }}</td>
                        <td>{{ it.default ? "Da" : "Ne" }}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel
                style="
                  border: 1px solid #e4e4e4;
                  border-radius: 0;
                  margin-bottom: -1px;
                "
              >
                <v-expansion-panel-header>
                  <v-row>
                    <v-col class="d-flex justify-center">
                      <p class="text-h6 font-weight-bold mb-0">
                        Opcije bez plaćanja
                      </p>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-data-table
                    :headers="tagHeaders"
                    :items="tagTableRows"
                    :single-expand="true"
                    :expanded.sync="expandedTags"
                    item-key="name"
                    show-expand
                  >
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-toolbar-title
                          >Popis opcija bez plaćanja</v-toolbar-title
                        >
                        <v-btn
                          class="mx-2 white--text okButton"
                          style="
                            border-color: #e4e4e4;
                            margin-top: 0.2rem;
                            height: 1.6rem;
                          "
                          outlined
                          small
                          @click="openAddTagToMenuForm()"
                          >Dodaj opciju bez plaćanja</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-toolbar>
                    </template>
                    <template v-slot:item="{ item, expand, isExpanded }">
                      <tr>
                        <td>{{ item.tagName }}</td>
                        <td>{{ item.tagType.name }}</td>
                        <td>
                          <v-btn icon @click="openAddTagToMenuForm(item)">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </td>
                        <td>
                          <v-btn icon @click="removeTagFromMenu(item)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </td>
                        <td>
                          <v-btn icon @click="expand(!isExpanded)">
                            <v-icon>{{
                              isExpanded ? "mdi-chevron-up" : "mdi-chevron-down"
                            }}</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                    <template v-slot:expanded-item="{ item }">
                      <tr>
                        <td>Tekst taga</td>
                        <td>Zadani artikl</td>
                      </tr>
                      <tr
                        v-for="(it, index) in item.tagTexts"
                        :key="item.id + index"
                      >
                        <td>{{ it.name }}</td>
                        <td>{{ it.default ? "Da" : "Ne" }}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel
                style="
                  border: 1px solid #e4e4e4;
                  border-radius: 0;
                  margin-bottom: -1px;
                "
              >
                <v-expansion-panel-header>
                  <v-row>
                    <v-col class="d-flex justify-center">
                      <p class="text-h6 font-weight-bold mb-0">
                        Opcije uz plaćanje
                      </p>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-data-table
                    :headers="optionHeaders"
                    :items="optionTableRows"
                    :single-expand="true"
                    :expanded.sync="expandedOptions"
                    item-key="name"
                    show-expand
                  >
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-toolbar-title>Popis opcija</v-toolbar-title>
                        <v-btn
                          class="mx-2 white--text okButton"
                          style="
                            border-color: #e4e4e4;
                            margin-top: 0.2rem;
                            height: 1.6rem;
                          "
                          outlined
                          small
                          @click="openAddOptionToMenuForm()"
                          >Dodaj plaćenu opciju</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-toolbar>
                    </template>
                    <template v-slot:item="{ item, expand, isExpanded }">
                      <tr>
                        <td>{{ item.optionName }}</td>
                        <!-- <td>{{ item.optionType.name }}</td> -->
                        <td>
                          <v-btn icon @click="openAddOptionToMenuForm(item)">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </td>
                        <td>
                          <v-btn icon @click="removeOptionFromMenu(item)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </td>
                        <td>
                          <v-btn icon @click="expand(!isExpanded)">
                            <v-icon>{{
                              isExpanded ? "mdi-chevron-up" : "mdi-chevron-down"
                            }}</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                    <template v-slot:expanded-item="{ item }">
                      <tr>
                        <td>Ime artikla</td>
                        <td>Šifra artikla</td>
                        <td>Zadani artikl</td>
                        <td>Cijena</td>
                      </tr>
                      <tr
                        v-for="(it, index) in item.items"
                        :key="item.id + it.id + index"
                      >
                        <td>{{ it.name }}</td>
                        <td>{{ it.code }}</td>
                        <td>{{ it.default ? "Da" : "Ne" }}</td>
                        <td>
                          <v-chip
                            v-for="(price, k) in it.selectedPrices"
                            :key="price.price + k"
                            class="mt-1 mr-0"
                          >
                            <div v-if="manualPriceInput">
                              {{ price.currency }}
                            </div>
                            <div v-else>
                              {{ price.price | money(1, price.currency) }}
                            </div>
                          </v-chip>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting"
            type="submit"
            id="lastElement"
          >
            {{ $t("$vuetify.pointOfSale.addPricelistItemBtn") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <add-price
      ref="addPrice"
      :taxes="taxes"
      :isManual="manualPriceInput"
      @add="onAddPrice"
    ></add-price>
    <add-product-to-menu
      ref="addProductToMenu"
      @add="onAddProduct"
    ></add-product-to-menu>
    <add-tag-to-menu ref="addTagToMenu" @add="onAddTag"></add-tag-to-menu>
    <add-option-to-menu
      ref="addOptionToMenu"
      @add="onAddOption"
    ></add-option-to-menu>
  </v-dialog>
</template>
<script>
import state from '@/state'
import { auth, df, functions } from '@/plugins/firebase'
import rules from '@/plugins/rules'
import AddPrice from '@/modules/point-of-sale/components/AddPrice'
import AddProductToMenu from '@/modules/point-of-sale/components/menu-item/AddProductToMenu'
import AddTagToMenu from '@/modules/point-of-sale/components/menu-item/AddTagToMenu'
import AddOptionToMenu from '@/modules/point-of-sale/components/menu-item/AddOptionToMenu'
import onScreenKeyboard from '@/mixins/onScreenKeyboard'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: { AddPrice, AddProductToMenu, AddTagToMenu, AddOptionToMenu },
  inject: ['showMsgBox', 'showLoader', 'hideLoader', 'showAlert'],
  mixins: [onScreenKeyboard],
  data: () => ({
    visible: false,
    menuName: '',
    expandedProducts: [],
    expandedTags: [],
    expandedOptions: [],
    submitting: false,
    loading: false,
    manualPriceInput: false,
    selectedPrices: [],
    previousPrices: [],
    taxes: [],
    products: [],
    tags: [],
    options: [],
    showProducts: false,
    showTags: false,
    showOptions: false,
    rules: {
      req: rules.req(),
      arrReq: rules.arrReq(),
      money: rules.money()
    },
    listeners: [],
    productHeaders: [
      {
        text: 'Ime stavke',
        value: 'productName',
        align: 'start'
      },
      {
        text: 'Tip stavke',
        value: 'productType'
      },
      {
        text: 'Uredi',
        value: ''
      },
      {
        text: 'Ukloni',
        value: ''
      },
      { text: '', value: 'data-table-expand' }
    ],
    productTableRows: [],
    tagHeaders: [
      {
        text: 'Ime stavke',
        value: 'optionName',
        align: 'start'
      },
      {
        text: 'Tip stavke',
        value: 'optionType'
      },
      {
        text: 'Uredi',
        value: ''
      },
      {
        text: 'Ukloni',
        value: ''
      },
      { text: '', value: 'data-table-expand' }
    ],
    optionHeaders: [
      {
        text: 'Ime stavke',
        value: 'tagName',
        align: 'start'
      },
      {
        text: 'Tip stavke',
        value: 'tagType'
      },
      {
        text: 'Uredi',
        value: ''
      },
      {
        text: 'Ukloni',
        value: ''
      },
      { text: '', value: 'data-table-expand' }
    ],
    tagTableRows: [],
    optionTableRows: [],
    selectedLocations: [],
    locations: []
  }),

  watch: {
  },
  mounted () {

  },
  beforeDestroy () {
    if (this.listeners) {
      this.detachListeners()
    }
  },
  methods: {
    preventTextInput (e) {
      if (e.keyCode !== 9 && e.keyCode !== 27) {
        this.openAddPriceForm()
        e.preventDefault()
      }
    },
    removeTagFromMenu (item) {
      const tmp = []
      this.tags.forEach(tag => {
        if (tag.id !== item.id) {
          tmp.push(tag)
        }
      })

      this.tags = tmp

      this.tagTableRows = this.tags
      if (this.tags === undefined || this.tags.length === 0) {
        this.tags = []
        this.showTags = false
      }
      this.$forceUpdate()
    },
    removeProductFromMenu (item) {
      const tmp = []
      this.products.forEach(product => {
        if (product.id !== item.id) {
          tmp.push(product)
        }
      })

      this.products = tmp

      this.productTableRows = this.products
      if (this.products === undefined || this.products.length === 0) {
        this.productTableRows = []
        this.showProducts = false
      }
      this.$forceUpdate()
    },
    removeOptionFromMenu (item) {
      const tmp = []
      this.options.forEach(option => {
        if (option.id !== item.id) {
          tmp.push(option)
        }
      })

      this.options = tmp

      this.optionTableRows = this.options
      if (this.options === undefined || this.options.length === 0) {
        this.optionTableRows = []
        this.showOptions = false
      }
      this.$forceUpdate()
    },
    onAddProduct (productItem, existing) {
      if (!existing) {
        this.products.push(productItem)
      } else {
        this.products.forEach((product, key) => {
          if (product.id === productItem.id) {
            this.products[key] = productItem
          }
        })
      }
      this.showProducts = true
      this.productTableRows = JSON.parse(JSON.stringify(this.products))
    },
    onAddTag (tagItem, existing) {
      if (!existing) {
        this.tags.push(tagItem)
      } else {
        this.tags.forEach((tag, key) => {
          if (tag.id === tagItem.id) {
            this.tags[key] = tagItem
          }
        })
      }

      this.showTags = true
      this.tagTableRows = JSON.parse(JSON.stringify(this.tags))
    },
    onAddOption (optionItem, existing) {
      if (!existing) {
        this.options.push(optionItem)
      } else {
        this.options.forEach((option, key) => {
          if (option.id === optionItem.id) {
            this.options[key] = optionItem
          }
        })
      }

      this.showOptions = true
      // this.optionTableRows = JSON.parse(JSON.stringify(this.options))
      this.optionTableRows = this.options
      this.$forceUpdate()
    },
    open () {
      this.visible = true
      this.listeners.push(
        df.doc('project_constants/taxes').onSnapshot((doc) => {
          if (doc.data() && doc.data().pdv && doc.data().pdv.length > 0) {
            // this.$refs.addCashRegisterForm.reset()
            this.taxes = doc.data().pdv.map((tax) => {
              return {
                ...tax,
                ...{
                  disabled:
                    !state.getCurrentCompany().is_taxed && tax.rate !== 0
                }
              }
            })
          }
        })
      )

      this.listeners.push(
        df
          .doc(
            `user_locations/${auth.currentUser.uid}.${
              state.getCurrentCompany().id
            }`
          )
          .onSnapshot((doc) => {
            if (
              doc.data() &&
              doc.data().locations &&
              Object.keys(doc.data().locations).length > 0
            ) {
              this.locations = Object.keys(doc.data().locations)
                .map((key) => {
                  return doc.data().locations[key]
                })
                .filter((location) => location.status === 'OK')

              this.selectedLocations = this.locations.map((location) => {
                return location.id
              })
            }
          })
      )
    },
    close () {
      this.detachListeners()
      this.$nextTick(() => {
        this.visible = false
        this.products = []
        this.tags = []
        this.menuName = ''
      })
    },
    openAddProductToMenuForm (productItem = undefined) {
      if (productItem !== undefined) {
        this.products.forEach(product => {
          if (product.id === productItem.id) {
            this.$refs.addProductToMenu.open(product, undefined, this.selectedLocations, this.locations)
          }
        })
      } else {
        this.$refs.addProductToMenu.open(undefined, undefined, this.selectedLocations, this.locations)
      }
    },
    openAddTagToMenuForm (existingTag = undefined) {
      if (existingTag !== undefined) {
        this.tags.forEach(tag => {
          if (tag.id === existingTag.id) {
            this.$refs.addTagToMenu.open(existingTag)
          }
        })
      } else {
        this.$refs.addTagToMenu.open()
      }
    },
    openAddOptionToMenuForm (existingOption = undefined) {
      if (existingOption !== undefined) {
        this.options.forEach(option => {
          if (option.id === existingOption.id) {
            this.$refs.addOptionToMenu.open(existingOption)
          }
        })
      } else {
        this.$refs.addOptionToMenu.open()
      }
    },
    openAddPriceForm () {
      this.$refs.addPrice.open()
    },
    onAddPrice (priceItem) {
      if (
        this.selectedPrices.some((item) => item.currency === priceItem.currency)
      ) {
        const sameItem = this.selectedPrices.find(
          (it) => it.currency === priceItem.currency
        )
        this.selectedPrices = this.selectedPrices.map((item) => {
          item.price = item === sameItem ? priceItem.price : item.price
          return item
        })
      } else {
        this.selectedPrices.push(priceItem)
      }
    },
    removePrice (priceItem) {
      this.selectedPrices = this.selectedPrices.filter(
        (item) => item !== priceItem
      )
    },
    editPrice (priceItem) {
      this.$refs.addPrice.open(priceItem)
    },
    detachListeners () {
      this.listeners.forEach((listener) => {
        listener()
        this.listeners = this.listeners.filter((l) => l !== listener)
      })
    },
    submit () {
      this.submitting = true

      const realProducts = []
      const realTags = []
      const realOptions = []

      this.products.forEach((product, key) => {
        const productItems = []
        realProducts.push({
          id: product.id,
          // product_items: product.items,
          name: product.productName,
          type: product.productType.value
        })

        product.items.forEach(item => {
          productItems.push({
            company_item: item,
            default: item.default
          })
        })

        realProducts[key].product_items = productItems
      })

      this.options.forEach((option, key) => {
        const optionItems = []
        realOptions.push({
          id: option.id,
          // product_items: product.items,
          name: option.optionName,
          type: option.optionType.value
        })

        option.items.forEach(item => {
          const pr = item.selectedPrices.reduce((prices, it) => {
            return Object.assign(prices, {
              [it.currency]: {
                active: true,
                price: it.price * 100,
                taxes: it.taxes.map((tax) => {
                  return { name: tax.name, rate: tax.rate }
                })
              }
            })
          }, {})

          delete item.selectedPrices

          optionItems.push({
            company_item: item,
            default: item.default,
            prices: pr
          })
        })

        realOptions[key].option_items = optionItems
      })

      this.tags.forEach((tag, key) => {
        const tagTexts = []

        realTags.push({
          id: tag.id,
          name: tag.tagName,
          type: tag.tagType.value
        })

        tag.tagTexts.forEach(tagText => {
          tagTexts.push({
            id: tagText.id,
            text: tagText.name,
            default: tagText.default
          })
        })

        realTags[key].tag_texts = tagTexts
      })

      let prices = {}
      if (this.manualPriceInput) {
        prices = this.selectedPrices.reduce((prices, it) => {
          return Object.assign(prices, {
            [it.currency]: {
              active: true,
              price: 0,
              taxes: it.taxes.map((tax) => {
                return { name: tax.name, rate: tax.rate }
              })
            }
          })
        }, {})
      } else {
        prices = this.selectedPrices.reduce((prices, it) => {
          return Object.assign(prices, {
            [it.currency]: {
              active: true,
              price: it.price * 100,
              taxes: it.taxes.map((tax) => {
                return { name: tax.name, rate: tax.rate }
              })
            }
          })
        }, {})
      }

      let manualPrice = false
      if (this.manualPriceInput !== undefined) {
        manualPrice = this.manualPriceInput
      }

      const categories = []
      categories.push({
        id: '5692439e-4269-4fd9-875e-71e0e08a4e99',
        name: 'Meni'
      })

      const payload = {
        action: {
          operation: 'set',
          entity: 'menu_item',
          params: {
            menu_item: {
              id: uuidv4(),
              name: this.menuName,
              products: realProducts,
              tags: realTags,
              options: realOptions
            },
            // code: this.code,
            // picture: this.itemImg,
            // type: this.type.value,
            company_id: state.getCurrentCompany().id,
            // storage_unit: this.storageUnit ? this.storageUnit.value : '',
            // selling_unit: this.sellingUnit ? this.sellingUnit.value : '',
            locations: this.selectedLocations,
            pricing_info: {
              active: true,
              categories: categories,
              prices: prices,
              manual_price_input: manualPrice
            }

          }
        }
      }

      // if (this.barcode !== '') {
      //   payload.action.params.barcode = this.barcode
      // }

      try {
        var companyitemApi = functions.httpsCallable('companyitemapi')

        companyitemApi({
          action: {
            operation: payload.action.operation,
            entity: payload.action.entity,
            resource_id: payload.action.resource_id,
            params: payload.action.params
          }
        })
          .then((result) => {
            if (result.data.code === 200) {
              this.$emit('refresh')
              this.$refs.menuForm.reset()
              this.submitting = false
              this.visible = false
            } else {
              this.showMsgBox({
                text: result.data.message ?? 'Dogodila se pogreška.',
                actions: ['cancel'],
                cancelBtnText: 'OK',
                color: 'error'
              })
              this.submitting = false
            }
          })
      } catch (err) {
        this.showMsgBox({
          text: err && err !== '' ? err : 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        this.submitting = false
      }
    },
    getWarehouseItems () {

    }
  }
}
</script>
<style>
.items {
  max-height: 300px;
  height: 300px;
  overflow-y: auto;
}

.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
