<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    scrollable
    style="z-index: 6"
    max-width="800"
    min-width="800"
  >
    <v-overlay v-show="loader" absolute z-index="10">
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <v-form ref="priceListForm" @submit.prevent="submit">
      <v-card
        class="overflow-auto pb-5"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h2 class="text--secondary">
              {{ $options.filters.capitalize(item.name) }}
            </h2>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-col
              cols="10"
              class="pt-0 pl-0 ml-0 pr-6"
              v-if="priceInEUR !== 0"
            >
              <v-col class="pt-0 pl-0 ml-0" v-if="priceInEUR !== 0">
                <v-card
                  :class="[profit > 0 ? 'margin-ok' : 'margin-not-ok']"
                  style="width: 80%"
                >
                  <v-card-text class="white--text">
                    <v-row class="">
                      <v-col cols="12" class="pr-0 mr-0">
                        <span
                          >Informativni izračun
                          <v-icon class="pb-1 pl-3" small @click="openDialog()"
                            >mdi-arrow-expand</v-icon
                          >
                          <br />
                        </span>
                        <span
                          >Profit: <b>{{ profit }} {{ currency }}</b> <br />
                        </span>
                        <span
                          >Marža bruto: <b>{{ marginBruto }} %</b> <br
                        /></span>
                        <span
                          >Postotak marže: <b>{{ marginPercent }} %</b></span
                        >
                      </v-col>
                    </v-row></v-card-text
                  >
                </v-card>
              </v-col>
              <v-dialog
                v-model="showMarginDialog"
                @keydown.esc="close"
                persistent
                scrollable
                style="z-index: 6"
                max-width="800"
              >
                <v-card
                  class="overflow-auto"
                  :class="{
                    'mobile-dialog-card': $vuetify.breakpoint.smAndDown,
                  }"
                >
                  <v-card-title class="d-flex" style="vertical-align: middle">
                    <v-col>
                      <v-row class="pa-3">
                        <h2 class="text--secondary">Izračun marže</h2>
                        <v-spacer></v-spacer>
                        <v-icon large @click="closeDialog()">mdi-close</v-icon>
                      </v-row>
                      <v-row class="pl-3">
                        Prodajna cijena:
                        {{ priceWithoutVat | money(1, currency) }} + PDV
                      </v-row>
                    </v-col>
                  </v-card-title>
                  <v-card-text class="pl-2 pr-2">
                    <v-data-table
                      v-if="margins.length === 1"
                      :headers="marginHeaders"
                      :items="margins[0].qbpp"
                      :items-per-page="-1"
                      class="elevation-3"
                      hide-default-footer
                    >
                      <template v-slot:item="{ item }">
                        <tr>
                          <td>
                            {{ $options.filters.capitalize(item.name) }}
                          </td>

                          <!-- Dostupna količina po određenoj cijeni -->
                          <td class="text-center">
                            {{ item.available_quantity }}
                          </td>
                          <!-- Količina artikla u normativu-->
                          <td class="text-center">
                            {{ item.quantity }}
                          </td>
                          <!-- Nabavna cijena po potrebnoj količini -->
                          <td class="text-center">
                            {{ item.purchase_price | money(1, currency) }}
                          </td>
                          <!-- Izračun trenutne marže -->
                          <td class="text-center">
                            {{
                              (priceWithoutVat - item.purchase_price)
                                | money(1, currency)
                            }}
                          </td>
                          <!-- Izračun trenutnog postotka marže -->
                          <td class="text-center">
                            {{
                              ((profit / item.purchase_price) * 100).toFixed(2)
                            }}
                            %
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                    <template v-for="(mg, kk) in margins">
                      <v-data-table
                        :key="kk"
                        v-if="margins.length > 1"
                        :headers="marginMultiItemHeaders"
                        :items="mg.qbpp"
                        :items-per-page="-1"
                        class="elevation-1"
                        hide-default-footer
                      >
                        <template v-slot:item="{ item }">
                          <tr>
                            <td>
                              {{ $options.filters.capitalize(item.name) }}
                            </td>
                            <td>{{ item.quantity }}</td>
                            <td>
                              {{ item.available_quantity }}
                            </td>
                            <td>
                              {{ item.purchase_price | money(1, currency) }}
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </template>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-col>
            <v-row>
              <v-col class="pb-0 pt-0">
                <v-checkbox
                  v-model="manualPriceInput"
                  label="Upis cijene na blagajni"
                >
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="7" class="pb-0">
                <v-select
                  multiple
                  outlined
                  dense
                  label="Odaberite kategoriju"
                  :items="categories"
                  return-object
                  item-text="name"
                  v-model="item.categories"
                  :rules="[rules.arrReq]"
                  :loading="categoryProcessing"
                ></v-select>
              </v-col>
              <v-col cols="5">
                <v-btn
                  x-small
                  class="mt-2"
                  style="z-index: 4"
                  bottom
                  right
                  plain
                  text
                  @click="openAddCategoryForm"
                >
                  <v-icon>mdi-plus</v-icon>
                  <span>Dodaj kategoriju</span>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="7" class="pb-0">
                <v-select
                  outlined
                  dense
                  label="Odaberite ustrojstvenu jedinicu"
                  :items="orgUnits"
                  item-text="name"
                  item-value="id"
                  v-model="item.organizational_unit_id"
                ></v-select>
              </v-col>
            </v-row>
            <v-row v-if="item !== undefined">
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <v-row class="pb-0">
                      <v-col cols="4">
                        <v-text-field
                          outlined
                          disabled
                          v-if="saleUnit !== undefined"
                          v-model="saleUnit.storage_unit"
                          label="Jedinica mjere"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <quantity-input
                          outlined
                          label="Količina"
                          disabled
                          v-if="saleUnit !== undefined"
                          :value="saleUnit.storage_amount / multiplier"
                        ></quantity-input>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          outlined
                          v-if="measureUnit !== undefined"
                          :value="measureUnit.storage_unit"
                          disabled
                        >
                        </v-text-field>
                      </v-col>
                      <div style="width: 10% !important">
                        <v-checkbox
                          outlined
                          width="20%"
                          v-if="saleUnit !== undefined"
                          v-model="saleUnit.active"
                          :disabled="
                            sellingUnits && sellingUnits.length > 2
                              ? true
                              : false
                          "
                          @click.stop=""
                          label="Aktivan"
                        >
                        </v-checkbox>
                      </div>
                      <div style="width: 5% !important" justify="center">
                        <v-icon
                          small
                          v-if="sellingUnits && sellingUnits.length > 2"
                          @click="showHelpPopup()"
                          @click.stop=""
                          >mdi-help</v-icon
                        >
                      </div>
                      <v-row class="pl-3">
                        <v-col cols="8" @click.stop="">
                          <v-combobox
                            outlined
                            autocomplete="off"
                            v-model="selectedPrices"
                            :items="[]"
                            dense
                            label="Cijene za artikl u valutama"
                            multiple
                            chips
                            clearable
                            @click="openAddPriceForm"
                            @keydown.enter="openAddPriceForm"
                            @keydown="preventTextInput($event)"
                            :rules="
                              saleUnit && saleUnit.active === true
                                ? [rules.arrReq]
                                : []
                            "
                          >
                            <template v-slot:selection="{ item }">
                              <v-chip
                                class="mt-1 mr-0"
                                close
                                @click="editPrice(item)"
                                @click:close="removePrice(item)"
                              >
                                <div v-if="manualPriceInput">
                                  {{ item.currency }}
                                </div>
                                <div v-else>
                                  {{ item.price | money(1, item.currency) }}
                                </div>
                              </v-chip>
                            </template>
                          </v-combobox>
                        </v-col>
                        <v-checkbox
                          @click.stop=""
                          class="pa-1"
                          v-model="rounding"
                          :disabled="saleUnit && saleUnit.active === false"
                          outlined
                          @change="roundAll()"
                          label="Cjelobrojno zaokruživanje"
                        >
                        </v-checkbox>
                      </v-row>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-col v-if="measureUnits.length > 0" class="pa-0">
                      <v-flex
                        v-for="(unit, idx) in measureUnits"
                        :key="idx"
                        class="pa-0"
                      >
                        <v-divider
                          v-if="unit.default_sale === false"
                        ></v-divider>
                        <v-row class="pa-5">
                          <v-col cols="4">
                            <v-text-field
                              outlined
                              disabled
                              v-model="unit.storage_unit"
                              v-if="
                                measureUnits.length > 0 &&
                                unit.default_sale === false
                              "
                              label="Jedinica mjere"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="3">
                            <quantity-input
                              outlined
                              v-if="
                                measureUnits.length > 0 &&
                                unit.default_sale === false
                              "
                              label="Količina"
                              disabled
                              :value="unit.storage_amount / multiplier"
                            ></quantity-input>
                          </v-col>
                          <v-col cols="3">
                            <v-text-field
                              outlined
                              v-if="
                                measureUnits.length > 0 &&
                                unit.default_sale === false
                              "
                              :value="measureUnit.storage_unit"
                              disabled
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="2">
                            <v-checkbox
                              outlined
                              :disabled="
                                unit.default_sale === true ? true : false
                              "
                              width="20%"
                              v-if="
                                measureUnits.length > 0 &&
                                unit.default_sale === false
                              "
                              v-model="unit.active"
                              label="Aktivan"
                            >
                            </v-checkbox>
                          </v-col>
                          <v-row class="pl-3 pr-3">
                            <v-col cols="10">
                              <v-combobox
                                outlined
                                autocomplete="off"
                                v-if="measureUnits[idx].default_sale !== true"
                                v-model="measureUnits[idx].prices"
                                :items="[]"
                                dense
                                label="Cijene za artikl u valutama"
                                multiple
                                chips
                                clearable
                                @click="openAddPriceWhForm(measureUnits[idx])"
                                @keydown.enter="
                                  openAddPriceWhForm(measureUnits[idx])
                                "
                                @keydown="preventTextInput($event)"
                                :rules="
                                  unit.active === true ? [rules.arrReq] : []
                                "
                              >
                                <template v-slot:selection="{ item }">
                                  <v-chip
                                    class="mt-1 mr-0"
                                    close
                                    @click="
                                      editPriceWh(item, measureUnits[idx])
                                    "
                                    @click:close="
                                      removePriceWh(item, measureUnits[idx])
                                    "
                                  >
                                    <div v-if="manualPriceInput">
                                      {{ item.currency }}
                                    </div>
                                    <div v-else>
                                      {{ item.price | money(1, item.currency) }}
                                    </div>
                                  </v-chip>
                                </template>
                              </v-combobox>
                            </v-col>
                          </v-row>
                        </v-row>
                      </v-flex>
                    </v-col>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="showNorms">
                  <v-expansion-panel-header>
                    <v-row class="d-flex justify-center pa-0" v-if="showNorms">
                      <p class="text-h6 align-center font-weight-bold">
                        Izračun nabavne cijene prema normativu
                      </p>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row class="d-flex justify-center pa-0">
                      <v-col cols="12" v-if="showNorms">
                        <v-data-table
                          v-if="showNorms"
                          :headers="normHeaders"
                          :items="normRows"
                          :items-per-page="-1"
                          class="elevation-1"
                          hide-default-footer
                        >
                          <template v-slot:item="{ item }">
                            <tr>
                              <td>{{ item.name }}</td>
                              <td>{{ item.quantity }}</td>
                              <td>
                                {{ item.purchasePrice | money(1, currency) }}
                              </td>
                            </tr>
                          </template>
                          <template slot="body.append">
                            <tr>
                              <th>Ukupna nabavna cijena</th>
                              <th></th>
                              <th>
                                {{
                                  sumField("purchasePrice") | money(1, currency)
                                }}
                              </th>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting"
            type="submit"
            id="lastElement"
          >
            Spremi artikl
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <add-category
      ref="addCategory"
      @processing="setCategoryProcessing"
    ></add-category>
    <add-price
      ref="addPrice"
      :taxes="taxes"
      :isManual="manualPriceInput"
      @add="onAddPrice"
      @addWh="onAddPriceWh"
    ></add-price>
  </v-dialog>
</template>
<script>

import state from '@/state'
import { df, functions } from '@/plugins/firebase'
import rules from '@/plugins/rules'
import AddPrice from '@/modules/point-of-sale/components/AddPrice'
import { clone } from '@/plugins/utils'
import AddCategory from '@/modules/company/components/AddCategory'
import QuantityInput from '../../../components/QuantityInput.vue'

export default {
  components: { AddCategory, AddPrice, QuantityInput },
  inject: ['showMsgBox', 'showLoader', 'hideLoader', 'showAlert', 'showMsgBoxHtml', 'confirm', 'confirmClose'],
  data: () => ({
    visible: false,
    submitting: false,
    loading: false,
    categoryProcessing: false,
    selectedItem: undefined,
    searchModel: '',
    item: {},
    warehouseItems: [],
    selectedCategories: [],
    categories: [],
    selectedTaxes: [],
    currency: 'EUR',
    taxes: [],
    selectedLocations: [],
    locations: [],
    selectedPrices: [],
    itemSelection: false,
    selected: false,
    saleUnit: undefined,
    rounding: false,
    measureUnits: [],
    tempPrices: [],
    normRows: [],
    showNorms: false,
    showMarginDialog: false,
    orgUnits: [],
    rules: {
      req: rules.req(),
      arrReq: rules.arrReq(),
      money: rules.money()
    },
    listeners: [],
    normHeaders: [{
      text: 'Artikl',
      value: 'name'
    },
    {
      text: 'Potrebna količina',
      value: 'quantity'
    },
    {
      text: 'Nabavna cijena za količinu',
      value: 'purchasePrice'
    }],
    marginHeaders: [{
      text: 'Artikl',
      value: 'name'
    },
    {
      text: 'Dostupne količine',
      value: 'available_quantity'
    },

    {
      text: 'Potrebna količina',
      value: 'quantity'
    },
    {
      text: 'Nabavna cijena za količinu',
      value: 'purchase_price'
    },
    {
      text: 'Iznos marže',
      value: 'margin_amount'
    },
    {
      text: 'Postotak marže',
      value: 'margin_percent'
    }
    ],
    marginMultiItemHeaders: [{
      text: 'Artikl',
      value: 'name'
    },
    {
      text: 'Potrebna količina',
      value: 'quantity'
    },
    {
      text: 'Dostupne količine',
      value: 'available_quantity'
    },
    {
      text: 'Nabavna cijena za količinu',
      value: 'purchase_price'
    }
    ],
    margins: [],
    manualPriceInput: false,
    priceInEUR: 0,
    priceInEURVat: 0,
    measureUnit: undefined,
    loader: false,
    sellingUnits: []
  }),

  computed: {
    priceWithoutVat () {
      if (this.priceInEURVat > 0) {
        return this.priceInEUR / (1 + this.priceInEURVat / 10000)
      } else {
        return this.priceInEUR
      }
    },
    profit () {
      if (this.priceInEUR && this.priceInEUR !== 0) {
        if (this.showNorms === true) {
          return (this.priceWithoutVat - this.sumField('purchasePrice')).toFixed(2)
        } else {
          if (this.selectedItem.warehouse_data && this.selectedItem.warehouse_data.purchase_price && this.selectedItem.warehouse_data.purchase_price > 0) {
            var purchasePriceWithoutMargin = this.selectedItem.warehouse_data.purchase_price
            if (this.selectedItem.warehouse_data.supplier_rebate && this.selectedItem.warehouse_data.supplier_rebate > 0) {
              purchasePriceWithoutMargin = this.selectedItem.warehouse_data.purchase_price - (this.selectedItem.warehouse_data.purchase_price * this.selectedItem.warehouse_data.supplier_rebate / 10000)
            }
            return parseFloat((this.priceWithoutVat - purchasePriceWithoutMargin / 100).toFixed(2))
          }

          return parseFloat(this.priceWithoutVat.toFixed(2))
        }
      } else {
        return 0
      }
    },
    marginBruto () {
      var marginP = 0
      if (this.profit && this.profit !== 0) {
        var priceWithoutVat = this.priceInEUR / (1 + this.priceInEURVat / 10000)
        return (this.profit / priceWithoutVat * 100).toFixed(2)

        // if (this.selectedItem && this.selectedItem.warehouse_data && this.selectedItem.warehouse_data.purchase_price && this.selectedItem.warehouse_data.purchase_price > 0) {
        //   return ((this.profit / (this.selectedItem.warehouse_data.purchase_price / 100)) * 100).toFixed(2)
        // } else {
        //   return 100
        // }
      }
      return marginP
    },
    marginPercent () {
      var marginP = 0
      if (this.profit && this.profit !== 0) {
        if (this.showNorms === true) {
          return ((this.profit / this.sumField('purchasePrice'))).toFixed(2)
        } else {
          if (this.selectedItem.warehouse_data && this.selectedItem.warehouse_data.purchase_price && this.selectedItem.warehouse_data.purchase_price > 0) {
            var purchasePriceWithoutMargin = this.selectedItem.warehouse_data.purchase_price
            if (this.selectedItem.warehouse_data.supplier_rebate && this.selectedItem.warehouse_data.supplier_rebate > 0) {
              purchasePriceWithoutMargin = this.selectedItem.warehouse_data.purchase_price - (this.selectedItem.warehouse_data.purchase_price * this.selectedItem.warehouse_data.supplier_rebate / 10000)
            }

            return ((this.profit / (purchasePriceWithoutMargin / 100)) * 100).toFixed(2)
          }
        }
      }
      return marginP
    }
  },
  mounted () {
    this.currency = 'HRK'
    if (Date.now() / 1000 > 1672527599) {
      this.currency = 'EUR'
    }
    this.getMultiplier()
    this.getWarehouse()

    document.onkeydown = this.handleKeyEvents
    const rule = (v) =>
      this.warehouseItems.includes(v) || 'Please select a warehouse item'

    this.rules = { ...this.rules, ...{ itemExists: rule } }
    this.listeners.push(
      df
        .doc(`company_categories/${state.getCurrentCompany().id}`)
        .onSnapshot((doc) => {
          if (
            doc &&
            doc.data() &&
            doc.data().categories &&
            Object.keys(doc.data().categories).length > 0
          ) {
            const categories = Object.keys(doc.data().categories).map((key) => {
              return doc.data().categories[key]
            })
            this.categories = clone(categories.sort((a, b) => a.name.localeCompare(b.name)))
            this.categoryProcessing = false
          }
        })
    )

    this.listeners.push(
      df.doc('project_constants/taxes').onSnapshot((doc) => {
        if (doc.data() && doc.data().pdv && doc.data().pdv.length > 0) {
          this.taxes = doc.data().pdv
        }
      })
    )
  },
  beforeDestroy () {
    if (this.listeners) {
      this.detachListeners()
    }
  },
  methods: {
    showHelpPopup () {
      this.showMsgBoxHtml({ text: 'Ukoliko želite deaktivirati zadanu prodajnu jedinicu, morate postaviti drugu jedinicu kao zadanu. <br/> To možete učiniti klikom na: <br/> Meni Artikli -> Lista artikala -> Uređivanje artikla -> Odabir druge zadane prodajne jedinice, te klik na gumb Spremi.', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
    },
    async checkDefaultUnitSelector (active) {
      if (active === false && this.sellingUnits.length === 2) {
        this.loader = true
        var units = []
        var item = clone(this.item)
        var defaultSale = clone(item.warehouse_units.default_sale)

        if (item.warehouse_units.units) {
          Object.keys(item.warehouse_units.units).forEach(key => {
            var unit = item.warehouse_units.units[key]
            if (unit.id === defaultSale) {
              item.warehouse_units.units[key].default_sale = false
            }

            if (unit.id !== defaultSale && unit.is_selling_item === true) {
              item.warehouse_units.units[key].default_sale = true
              item.warehouse_units.default_sale = unit.id
            }

            units.push(item.warehouse_units.units[key])
          })
        }

        var newItem = {
          warehouse_units: item.warehouse_units
        }
        newItem.warehouse_units.units = units

        var that = this
        const payload = {
          action: {
            operation: 'update',
            entity: 'warehouse_module_item',
            resource_id: item.id,
            params: {
              ...newItem,
              ...{ company_id: state.getCurrentCompany().id }
            }
          }
        }

        try {
          var companyitemApi = functions.httpsCallable('companyitemapi')

          companyitemApi({
            action: {
              operation: payload.action.operation,
              entity: payload.action.entity,
              resource_id: payload.action.resource_id,
              params: payload.action.params
            }
          })
            .then((result) => {
              if (result.data.code === 200) {
                this.loader = false
              } else {
                this.showMsgBox({
                  text: result.data.message ?? 'Dogodila se pogreška.',
                  actions: ['cancel'],
                  cancelBtnText: 'OK',
                  color: 'error'
                })
                this.close()
                this.loader = false
                this.submitting = false
              }
            })
        } catch (err) {
          that.showMsgBox({
            text: err && err !== '' ? err : 'An error has occurred',
            actions: ['cancel'],
            cancelBtnText: 'OK',
            color: 'error'
          })
          // that.loader = false
        }
      }
    },

    closeDialog () {
      this.showMarginDialog = false
    },
    openDialog () {
      this.showMarginDialog = true
    },
    sumField (key) {
      // sum data in give key (property)
      return this.normRows.reduce((a, b) => a + (b[key] || 0), 0)
    },
    roundAll () {
      if (this.measureUnits.length > 0) {
        this.selectedPrices.forEach(price => {
          this.measureUnits.forEach(unit => {
            unit.prices.forEach(unitPrice => {
              if (price.currency === unitPrice.currency) {
                if (this.rounding) {
                  unitPrice.price = Math.ceil((price.price / this.saleUnit.storage_amount) * unit.storage_amount)
                } else {
                  unitPrice.price = ((price.price / this.saleUnit.storage_amount) * unit.storage_amount)
                }
              }
            })
          })
        })
      }
    },
    async setSelectedItemValues () {
      this.showNorms = false
      this.margins = []
      if (this.selectedItem.warehouse_units && this.selectedItem.warehouse_units.units) {
        await df.doc(`warehouses/${this.warehouse.id}/extended_items/${this.selectedItem.id}`)
          .get()
          .then(resp => {
            const extItem = resp.data()
            this.selectedItem.warehouse_data = extItem.warehouse_data

            this.prepareUnits()
            this.prepareItem()
          })
      }
    },

    async prepareItem () {
      await df.doc(`companies/${state.getCurrentCompany().id}/items/${this.item.id}`)
        .get()
        .then(resp => {
          if (resp !== undefined) {
            const item = resp.data()

            if (item.norm !== undefined && item.norm.components !== undefined) {
              this.calculateItemNormMargins(item)
            }
            setTimeout(() => {
              if (this.selectedItem && this.selectedItem.norm && this.selectedItem.norm.components) {
                Object.keys(this.selectedItem.norm.components).forEach(compKey => {
                  let pp = this.selectedItem.norm.components[compKey].purchasePrice

                  if (this.selectedItem.norm.components[compKey].hasCustom === true) {
                    pp = pp * this.selectedItem.norm.components[compKey].customQuantity
                  }

                  this.normRows.push({ name: this.selectedItem.norm.components[compKey].item_name, quantity: this.selectedItem.norm.components[compKey].quantity / this.multiplier, purchasePrice: pp * (this.selectedItem.norm.components[compKey].quantity / this.multiplier) })
                })
              }
            }, 1000)

            if (item && !item.norm) {
              this.setNormalItemMargin()
            }
          }
        })
    },

    prepareUnits () {
      Object.keys(this.selectedItem.warehouse_units.units).forEach(unit => {
        if (this.selectedItem.warehouse_units.units[unit].is_selling_item === true) {
          this.sellingUnits.push(this.selectedItem.warehouse_units.units[unit])
        }
        if (this.selectedItem.warehouse_units.units[unit].default_sale === true) {
          this.pricePrepare(unit, 1)
        }

        if (this.selectedItem.warehouse_units.units[unit].is_selling_item === true && this.selectedItem.warehouse_units.units[unit].default_sale === false && this.selectedItem.warehouse_units.units[unit].base_unit === false) {
          this.pricePrepare(unit, 2)
        }

        if (this.selectedItem.warehouse_units.units[unit].base_unit === true) {
          this.pricePrepare(unit, 3)
        }
      })
    },
    pricePrepare (unit, mode = undefined) {
      if (this.selectedItem.warehouse_units.units[unit].prices) {
        Object.keys(this.selectedItem.warehouse_units.units[unit].prices).forEach((key) => {
          const item = clone(this.selectedItem.warehouse_units.units[unit].prices[key])

          item.currency = key
          item.price = (item.price / 100).toFixed(2)
          item.base = (item.base / 100).toFixed(2)

          if (mode === 1 && key === 'EUR') {
            this.priceInEUR = parseFloat(item.price / (this.selectedItem.warehouse_units.units[unit].storage_amount / this.multiplier).toFixed(2))
          }

          this.selectedItem.warehouse_units.units[unit].active = this.selectedItem.warehouse_units.units[unit].prices[key].active ? this.selectedItem.warehouse_units.units[unit].prices[key].active : false
          item.taxes = item.taxes.map((tax) => {
            return {
              name: tax.name,
              rate: tax.rate ? tax.rate : 0
            }
          })

          switch (mode) {
            case 1:
              this.selectedPrices.push(item)
              break
            default:
              this.tempPrices.push(item)
              break
          }
        })
      }

      switch (mode) {
        case 1:
          this.saleUnit = this.selectedItem.warehouse_units.units[unit]
          if (this.saleUnit.prices && this.saleUnit.prices > 0) {
            this.saleUnit.prices.forEach((price, key) => {
              if (price.price) {
                this.saleUnit.prices[key].price *= 100
              }
            })
          }
          break
        case 2:
          this.selectedItem.warehouse_units.units[unit].prices = this.tempPrices
          this.tempPrices = []

          this.measureUnits.push(this.selectedItem.warehouse_units.units[unit])
          break
        case 3:
          this.selectedItem.warehouse_units.units[unit].prices = this.tempPrices
          this.tempPrices = []

          this.measureUnit = this.selectedItem.warehouse_units.units[unit]
          this.measureUnits.push(this.selectedItem.warehouse_units.units[unit])

          break
      }
    },

    setNormalItemMargin () {
      const tempMargin = []
      if (this.selectedItem.warehouse_data.quantity_by_purchase_price && this.selectedItem.warehouse_data.quantity_by_purchase_price.length > 0) {
        this.selectedItem.warehouse_data.quantity_by_purchase_price.forEach(qbpp => {
          if (qbpp.available_quantity > 0) {
            tempMargin.push({ name: this.selectedItem.name, quantity: 1, available_quantity: qbpp.available_quantity / this.multiplier, purchase_price: qbpp.purchase_price / 100, marginAmount: 0, marginPercent: 0 })
          }
        })
        this.margins.push({ name: this.selectedItem.name, qbpp: tempMargin })
      }
    },
    calculateItemNormMargins (item) {
      this.selectedItem.norm = item.norm
      Object.keys(item.norm.components).forEach(compKey => {
        df.doc(`warehouses/${this.warehouse.id}/extended_items/${compKey}`)
          .get()
          .then(resp => {
            const extItem = resp.data()
            const tempMargin = []
            if (extItem.warehouse_data.quantity_by_purchase_price && extItem.warehouse_data.quantity_by_purchase_price !== null && extItem.warehouse_data.quantity_by_purchase_price.length > 0) {
              extItem.warehouse_data.quantity_by_purchase_price.forEach(qbpp => {
                if (qbpp.available_quantity > 0) {
                  tempMargin.push({ name: extItem.item.name, quantity: this.selectedItem.norm.components[compKey].quantity / this.multiplier, available_quantity: qbpp.available_quantity / this.multiplier, purchase_price: (qbpp.purchase_price / 100) * (this.selectedItem.norm.components[compKey].quantity / this.multiplier), marginAmount: 0, marginPercent: 0 })
                }
              })

              this.margins.push({ name: extItem.item.name, quantity: this.selectedItem.norm.components[compKey].quantity / this.multiplier, qbpp: tempMargin })
            }

            let tempPp = 0
            if (extItem.warehouse_data.quantity_by_purchase_price && extItem.warehouse_data.quantity_by_purchase_price !== null && extItem.warehouse_data.quantity_by_purchase_price.length > 0) {
              let found = false
              extItem.warehouse_data.quantity_by_purchase_price.forEach(qbpp => {
                if (qbpp.available_quantity > 0 && !found) {
                  tempPp = qbpp.purchase_price / 100
                  found = true
                }
              })
            }

            let pp = 0
            if (tempPp === 0) {
              pp = extItem.warehouse_data.purchase_price
            } else {
              pp = tempPp
            }
            this.selectedItem.norm.components[compKey].purchasePrice = pp

            this.selectedItem.norm.components[compKey].hasCustom = false
            this.selectedItem.norm.components[compKey].customQuantity = 0

            if (this.selectedItem.norm.components[compKey].custom_unit === true) {
              const customUnitId = this.selectedItem.norm.components[compKey].custom_unit_id
              df.doc(`companies/${state.getCurrentCompany().id}/items/${compKey}`)
                .get()
                .then(lagerResp => {
                  const lagerItem = lagerResp.data()
                  if (lagerItem.warehouse_units !== undefined && lagerItem.warehouse_units.units !== undefined && lagerItem.warehouse_units.units[customUnitId]) {
                    this.selectedItem.norm.components[compKey].hasCustom = true
                    this.selectedItem.norm.components[compKey].customQuantity = lagerItem.warehouse_units.units[customUnitId].storage_amount / this.multiplier
                  }
                })
            }
          })
      })
      this.showNorms = true
    },

    preventTextInput (e) {
      if (e.keyCode !== 9 && e.keyCode !== 27) {
        this.openAddPriceForm()
        e.preventDefault()
      }
    },
    async getWarehouse () {
      const that = this
      const query = df
        .collection('warehouses/')
        .where('company_id', '==', state.getCurrentCompany().id)
        .where('selling_warehouse', '==', true)
        .where('status', '==', 'OK')
        .where('location_id', '==', state.getPointOfSale().id)

      query.get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            that.warehouse = doc.data()
          })
        })
    },
    handleKeyEvents (e) {
      e = e || window.event
      if (e.keyCode === 9) {
        if (document.activeElement.id.includes('lastElement')) {
          e.preventDefault()
          document.getElementById('itemSelection').focus()
        }
      }
    },
    setFocusOnNext () {
      document.getElementById('locations').focus()
    },
    open (item, external = false) {
      this.sellingUnits = []
      this.margins = []
      this.priceInEUR = 0
      this.priceInEURVat = 0

      if (!external) {
        this.visible = true
        this.submitting = false
      }

      df.collection(`location_units/${state.getPointOfSale().id}/units`)
        .get()
        .then((documentSnapshots) => {
          if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
            this.orgUnits = []
            const orgUnits = []
            documentSnapshots.docs.forEach((orgUnit) => {
              orgUnits.push(orgUnit.data())
            })
            this.orgUnits = clone(orgUnits.sort((a, b) => a.name.localeCompare(b.name)))
          }
        })

      this.item = clone(item)
      this.selectedItem = clone(item)

      this.setSelectedItemValues()
      setTimeout(() => {
        this.selectedPrices.forEach(price => {
          if (price.currency === this.currency) {
            // this.priceInEUR = price.price
            this.priceInEURVat = price.taxes[0].rate
          }
        })
      }, 600)

      // Object.keys(this.item.prices).forEach((key) => {
      //   const item = this.item.prices[key]
      //   item.price = (item.price / 100).toFixed(2)
      //   item.taxes = item.taxes.map((tax) => {
      //     return {
      //       name: tax.name,
      //       rate: tax.rate ? tax.rate : 0
      //     }
      //   })
      //   this.selectedPrices.push(item)
      // })
    },
    close () {
      this.detachListeners()

      this.selectedItem = undefined
      this.measureUnits = []
      this.measureUnit = undefined
      this.normRows = []
      this.priceInEUR = 0
      this.selectedCategories = []
      this.selectedPrices = []
      this.$nextTick(() => {
        this.visible = false
      })
    },
    openAddCategoryForm () {
      this.$refs.addCategory.open()
    },
    openAddPriceWhForm (unit) {
      this.$refs.addPrice.open(null, state.getPointOfSale(), unit.id)
    },
    openAddPriceForm () {
      this.$refs.addPrice.open(null, state.getPointOfSale())
    },
    onAddPrice (priceItem) {
      if (
        this.selectedPrices.some((item) => item.currency === priceItem.currency)
      ) {
        const sameItem = this.selectedPrices.find(
          (it) => it.currency === priceItem.currency
        )
        this.selectedPrices = this.selectedPrices.map((item) => {
          item.price = item === sameItem ? priceItem.price : item.price
          item.taxes = priceItem.taxes
          item.base = priceItem.base
          return item
        })
      } else {
        this.selectedPrices.push(priceItem)
      }

      this.selectedPrices.forEach(price => {
        if (price.currency === 'EUR') {
          this.priceInEUR = parseFloat((price.price) / (this.saleUnit.storage_amount / this.multiplier).toFixed(2))
          this.priceInEURVat = price.taxes[0].rate
        }
      })
      if (this.measureUnits.length > 1) {
        this.confirm({
          title: 'Promjena cijene',
          message: 'Promijenili ste cijenu zadanoj prodajnoj mjernoj jedinici. Želite li promijeniti i ostale cijene u odnosu?',
          options: {
            toolbar: true,
            width: 410,
            confirmText: 'DA',
            cancelText: 'NE'
          }
        }).then((resp) => {
          if (resp) {
            this.confirmClose()
            this.measureUnits.forEach((unit, key) => {
              if (unit.default_sale === false) {
                if (
                  this.measureUnits[key].prices.some((item) => item.currency === priceItem.currency)
                ) {
                  const onePrice = priceItem.price / this.saleUnit.storage_amount

                  const sameItem = this.measureUnits[key].prices.find(
                    (it) => it.currency === priceItem.currency
                  )
                  this.measureUnits[key].prices = this.measureUnits[key].prices.map((item) => {
                    if (item === sameItem) {
                      if (this.rounding) {
                        Math.ceil(this.measureUnits[key].storage_amount * onePrice)
                      } else {
                        item.price = this.measureUnits[key].storage_amount * onePrice
                      }
                    }
                    return item
                  })
                } else {
                  const itemPrice = clone(priceItem)
                  const clonePrice = clone(priceItem.price)

                  const onePrice = (clonePrice / this.saleUnit.storage_amount)

                  if (this.rounding) {
                    itemPrice.price = Math.ceil(this.measureUnits[key].storage_amount * onePrice)
                  } else {
                    itemPrice.price = this.measureUnits[key].storage_amount * onePrice
                  }

                  this.measureUnits[key].prices.push(itemPrice)
                }
              }
            })
          }
        })
      }
    },
    onAddPriceWh (priceItem, unitID) {
      this.measureUnits.forEach((unit, key) => {
        if (unit.id === unitID) {
          if (
            this.measureUnits[key].prices.some((item) => item.currency === priceItem.currency)
          ) {
            const sameItem = this.measureUnits[key].prices.find(
              (it) => it.currency === priceItem.currency
            )
            this.measureUnits[key].prices = this.measureUnits[key].prices.map((item) => {
              item.price = item === sameItem ? priceItem.price : item.price
              item.base = priceItem.base
              return item
            })
          } else {
            this.measureUnits[key].prices.push(priceItem)
          }
        }
      })
    },
    removePriceWh (priceItem, un) {
      this.measureUnits.forEach((unit, key) => {
        if (un.id === unit.id) {
          this.measureUnits[key].prices = this.measureUnits[key].prices.filter(
            (item) => item !== priceItem
          )
        }
      })
    },
    editPriceWh (priceItem, unit) {
      this.$refs.addPrice.open(priceItem, state.getPointOfSale(), unit.id)
    },
    removePrice (priceItem) {
      this.selectedPrices = this.selectedPrices.filter(
        (item) => item !== priceItem
      )

      this.measureUnits.forEach((unit, key) => {
        this.measureUnits[key].prices = this.measureUnits[key].prices.filter(
          (item) => item.currency !== priceItem.currency
        )
      })
    },
    editPrice (priceItem) {
      this.$refs.addPrice.open(priceItem, state.getPointOfSale())
    },
    setCategoryProcessing (processing) {
      if (processing) {
        this.categoryProcessing = true
      } else {
        this.categoryProcessing = false
      }
    },
    detachListeners () {
      this.listeners.forEach((listener) => {
        listener()
        this.listeners = this.listeners.filter((l) => l !== listener)
      })
    },
    submit (external = false) {
      if (!this.$refs.priceListForm.validate()) {
        return
      }

      if (this.saleUnit.active === false && this.sellingUnits.length === 2) {
        this.checkDefaultUnitSelector(this.saleUnit.active)
        setTimeout(() => {
          this.callSubmit()
        }, 4000)
      } else {
        this.callSubmit()
      }
    },
    callSubmit () {
      // const categories = this.selectedCategories.map((category) => {
      //   return {
      //     id: category.id,
      //     name: category.name,
      //     picture: category.picture
      //   }
      // })
      try {
        this.submitting = true
        let prices = {}

        let manualPrice = false
        if (this.manualPriceInput !== undefined) {
          manualPrice = this.manualPriceInput
        }

        var active = false

        prices = this.selectedPrices.reduce((prices, it) => {
          if (this.saleUnit.active === true) {
            active = true
          }
          return Object.assign(prices, {
            [it.currency]: {
              active: this.saleUnit.active,
              price: Math.round(it.price * 100),
              base: Math.round(it.base * 100),
              taxes: it.taxes.map((tax) => {
                return { name: tax.name, rate: tax.rate }
              })
            }
          })
        }, {})

        const unitPrices = []
        if (this.saleUnit !== undefined && this.saleUnit.id !== undefined) {
          unitPrices.push({
            unit_id: this.saleUnit.id,
            prices: prices
          })
        }

        this.measureUnits.forEach((unit, key) => {
          if (unit.id !== this.saleUnit.id) {
            prices = this.measureUnits[key].prices.reduce((prices, it) => {
              if (this.measureUnits[key].active === true) {
                active = true
              }
              return Object.assign(prices, {
                [it.currency]: {
                  active: this.measureUnits[key].active,
                  price: Math.round(it.price * 100),
                  base: Math.round(it.base * 100),
                  taxes: it.taxes.map((tax) => {
                    return { name: tax.name, rate: tax.rate }
                  })
                }
              })
            }, {})

            unitPrices.push({
              unit_id: this.measureUnits[key].id,
              prices: prices
            })
          }
        })

        const payload = {
          action: {
            operation: 'update',
            entity: 'warehouse_module_pricelist_item',
            resource_id: this.item.id,
            params: {
              location_id: state.getPointOfSale().id,
              company_id: state.getCurrentCompany().id,
              pricing_info: {
                active: active,
                categories: this.item.categories,
                manual_price_input: manualPrice,
                unit_prices: unitPrices
              },
              organizational_unit_id: this.item.organizational_unit_id && this.item.organizational_unit_id !== '' ? this.item.organizational_unit_id : ''
            }
          }
        }

        var companyitemApi = functions.httpsCallable('companyitemapi')

        companyitemApi({
          action: {
            operation: payload.action.operation,
            entity: payload.action.entity,
            resource_id: payload.action.resource_id,
            params: payload.action.params
          }
        })
          .then((result) => {
            if (result.data.code === 200) {
              this.loader = false
              this.submitting = false
            } else {
              this.showMsgBox({
                text: result.data.message ?? 'Dogodila se pogreška.',
                actions: ['cancel'],
                cancelBtnText: 'OK',
                color: 'error'
              })
              this.loader = false
              this.submitting = false
              this.close()
            }
            this.$nextTick(() => {
              this.loader = false
              this.close()
              this.selectedPrices = []
              this.visible = false
            })
          })
      } catch (err) {
        this.showMsgBox({
          text: err && err !== '' ? err : 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        this.loader = false
        this.submitting = false
        this.close()
      }
    },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    }
  }
}
</script>
<style>
.items {
  max-height: 300px;
  height: 300px;
  overflow-y: auto;
}

.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
