<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    scrollable
    max-width="45%"
    max-height="100%"
    style="z-index: 6"
  >
    <v-form ref="addOptionToMenuForm" @submit.prevent="submit">
      <v-card
        class="overflow-auto pb-5"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="mb-2">
            <v-col class="d-flex justify-start">
              <h2 class="text--secondary">Plaćena opcija</h2>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex justify-end">
              <v-icon large @click="close">mdi-close</v-icon>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="10" class="d-flex justify-start">
                <v-select
                  flat
                  class="pt-1"
                  :items="optionTypes"
                  v-model="optionType"
                  dense
                  outlined
                  return-object
                  item-text="name"
                  label="Vrsta stavke"
                  autofocus
                  :rules="[rules.req]"
                ></v-select>
              </v-col>
              <v-col cols="2" class="d-flex justify-end">
                <v-btn
                  v-if="!addButtonHidden"
                  outlined
                  class="white--text okButton mx-2 mt-1"
                  height="40"
                  @keyup.enter="addItem"
                  @click.stop="addItem"
                  :disabled="!optionType"
                  >Odaberi</v-btn
                >
              </v-col>
            </v-row>
            <v-card v-if="option !== undefined && option !== null">
              <v-card-text>
                <v-row>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field
                      outlined
                      dense
                      class="compact-form"
                      label="Ime opcije"
                      v-model="option.optionName"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mb-2">
                  <v-col cols="10" style="padding: 0">
                    <v-autocomplete
                      dense
                      outlined
                      class="compact-form"
                      label="Artikl"
                      v-model="option.itemSelected"
                      item-text="name"
                      return-object
                      :items="items"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="2" style="padding: 0">
                    <v-btn
                      dense
                      height="40px"
                      class="white--text okButton compact-form"
                      @keyup.enter="addItemToOption(option.itemSelected)"
                      @click.stop="addItemToOption(option.itemSelected)"
                      >Dodaj</v-btn
                    >
                  </v-col>
                </v-row>
                <v-row v-for="(item, itKey) in option.items" :key="itKey">
                  <v-col
                    cols="4"
                    style="padding: 0"
                    class="d-flex justify-start"
                  >
                    <v-text-field
                      class="compact-form"
                      outlined
                      dense
                      label="Sastojak"
                      disabled
                      :value="item.name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" style="padding: 0">
                    <v-checkbox
                      v-model="item.default"
                      style="margin-top: 14px"
                      class="compact-form"
                      outlined
                      @change="changeDefault(item)"
                      label="Zadano"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="4" style="padding: 0">
                    <v-combobox
                      outlined
                      dense
                      class="compact-form"
                      autocomplete="off"
                      v-model="item.selectedPrices"
                      :items="[]"
                      label="Cijene za artikla u valutama"
                      multiple
                      chips
                      clearable
                      @click="openAddPriceForm(item)"
                      @keydown.enter="openAddPriceForm(item)"
                      @keydown="preventTextInput($event)"
                    >
                      <template v-slot:selection="{ item }">
                        <v-chip
                          class="mt-1 mr-0"
                          close
                          @click="editPrice(item)"
                          @click:close="removePrice(item)"
                        >
                          <div v-if="manualPriceInput">
                            {{ item.currency }}
                          </div>
                          <div v-else>
                            {{ item.price | money(1, item.currency) }}
                          </div>
                        </v-chip>
                      </template>
                    </v-combobox>
                  </v-col>
                  <v-col cols="2" style="padding: 0" class="d-flex justify-end">
                    <v-btn
                      dense
                      height="40px"
                      class="white--text okButton compact-form"
                      @click="removeItemFromOption(item)"
                      >Ukloni</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting"
            type="submit"
          >
            Spremi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <add-item-price
      ref="addPrice"
      :taxes="taxes"
      :isManual="manualPriceInput"
      @add="onAddPrice"
    ></add-item-price>
  </v-dialog>
</template>
<script>
import { df } from '@/plugins/firebase'
import state from '@/state'
import rules from '@/plugins/rules'
import { clone } from '@/plugins/utils'
import { v4 as uuidv4 } from 'uuid'
import AddItemPrice from '@/modules/point-of-sale/components/menu-item/AddItemPrice'

export default {
  name: 'AddOptionToMenu',
  components: { AddItemPrice },
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  data: () => ({
    visible: false,
    name: undefined,
    existing: false,
    submitting: false,
    selectedPrices: [],
    rules: {
      req: rules.req('Required field'),
      arrReq: rules.arrReq(),
      money: rules.money()
    },
    items: [],
    itemSelected: undefined,
    optionItems: [],
    optionType: { name: 'Višestruki odabir - obavezan', value: 'manC' },
    option: undefined,
    edit: false,
    taxes: [],
    manualPriceInput: false,
    listeners: [],
    addButtonHidden: false
  }),
  computed: {
    optionTypes () {
      return [
        { name: 'Višestruki odabir - obavezan', value: 'manC' },
        { name: 'Višestruki odabir - opcionalan', value: 'optC' },
        { name: 'Jednostruki odabir - obavezan', value: 'manR' },
        { name: 'Jednostruki odabir - opcionalan', value: 'optR' }
      ]
    }
  },
  watch: {
    option: {
      immediate: false,
      deep: true,
      handler () {
        this.addButtonHidden = true
      }
    }
  },
  methods: {
    beforeDestroy () {
      if (this.listeners) {
        this.detachListeners()
      }
    },
    detachListeners () {
      this.listeners.forEach((listener) => {
        listener()
        this.listeners = this.listeners.filter((l) => l !== listener)
      })
    },
    changeDefault (item) {
      if (this.optionType.value === 'manC' || this.optionType.value === 'optC') {
        return
      }
      if (this.option.items !== undefined && this.option.items !== null && this.option.items.length > 0) {
        const option = this.option.items.find(t => t.id === item.id)
        this.option.items.forEach((i) => {
          i.default = i.id === option.id
        })
        this.$forceUpdate()
      }
    },
    addItemToOption (itemSelected) {
      if (this.option.items === undefined || this.option.items === null) {
        this.option.items = []
      }

      itemSelected.default = false
      itemSelected.selectedPrices = []

      this.option.items.push(itemSelected)
      this.option.itemSelected = undefined
    },
    removeItemFromOption (itemSelected) {
      this.option.items = this.option.items.filter(item => {
        if (item.id !== itemSelected.id) {
          return item
        }
      })

      const temp = this.option.items
      this.option.items = []
      this.option.items = temp

      this.$forceUpdate()
    },
    removeItem (rmItem) {
      this.items = this.items.filter(item => {
        if (item.id !== rmItem.id) {
          return item
        }
      })
    },
    addItem () {
      const newOption = {
        id: uuidv4(),
        optionType: this.optionType
      }

      this.option = newOption
    },
    findType (type) {
      switch (type) {
        case 'manC':
          return 'Višestruki odabir - obavezan'
        case 'optC':
          return 'Višestruki odabir - opcionalan'
        case 'manR':
          return 'Jednostruki odabir - obavezan'
        case 'optR':
          return 'Jednostruki odabir - opcionalan'
      }
    },
    async open (existing = undefined, edit = false) {
      this.edit = edit
      this.addButtonHidden = false

      this.listeners.push(
        df.doc('project_constants/taxes').onSnapshot((doc) => {
          if (doc.data() && doc.data().pdv && doc.data().pdv.length > 0) {
            // this.$refs.addCashRegisterForm.reset()
            this.taxes = doc.data().pdv.map((tax) => {
              return {
                ...tax,
                ...{
                  disabled:
                    !state.getCurrentCompany().is_taxed && tax.rate !== 0
                }
              }
            })
          }
        })
      )

      const unsortedItems = []

      const query = await df
        .collection(`companies/${state.getCurrentCompany().id}/items`)
        .where('status', '==', 'OK')

      query.get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            const item = doc.data()
            if (item.warehouse_units !== undefined && item.warehouse_units.units !== undefined) {
              Object.keys(item.warehouse_units.units).forEach(key => {
                const newItem = clone(item)
                newItem.name = item.warehouse_units.units[key].storage_unit
                newItem.prices = item.warehouse_units.units[key].prices
                // if (item.warehouse_units.units[key].storage_unit && item.warehouse_units.units[key].storage_unit !== '') {
                //   newItem.name = item.warehouse_units.units[key].storage_unit
                // } else {
                //   newItem.name = item.name
                // }

                var stUnit = item.warehouse_units.units[key].storage_unit
                if (stUnit === '.kom') {
                  stUnit = ''
                }

                newItem.name = item.name + ' ' + stUnit
                newItem.id = item.id + '.' + item.warehouse_units.units[key].id

                unsortedItems.push(newItem)
              })
            } else {
              unsortedItems.push(item)
            }
          })
          this.items = clone(unsortedItems.sort((a, b) => a.name.localeCompare(b.name)))
        })

      this.visible = true

      if (existing !== undefined && !edit) {
        this.existing = true
        this.option = existing
      }

      // Transformacija za edit - NE DIRATI
      if (existing !== undefined && edit) {
        this.existing = true
        this.edit = true

        const ex = clone(existing)

        const optionType = {
          name: this.findType(ex.type),
          value: ex.type
        }

        this.option = {
          id: ex.id,
          optionName: ex.name,
          optionType: optionType
        }

        if (ex.option_items !== undefined && ex.option_items.length > 0) {
          this.option.items = []
          ex.option_items.forEach(pi => {
            pi.company_item.default = pi.default

            const pr = []

            Object.keys(pi.prices).forEach((key) => {
              const item = pi.prices[key]
              item.price = (item.price / 100).toFixed(2)
              item.itemId = pi.company_item.id
              item.taxes = item.taxes.map((tax) => {
                return {
                  name: tax.name,
                  rate: tax.rate ? tax.rate : 0
                }
              })
              pr.push(item)
            })

            pi.company_item.selectedPrices = pr

            // pi.company_item.selectedPrices = pi.prices

            this.option.items.push(pi.company_item)
          })
        }
      }
    },
    openAddPriceForm (item) {
      this.$refs.addPrice.open(undefined, item)
    },
    onAddPrice (priceItem) {
      this.option.items.forEach(i => {
        if (priceItem.itemId !== undefined) {
          if (priceItem.itemId === i.id) {
            if (
              i.selectedPrices !== undefined && i.selectedPrices.some((item) => item.currency === priceItem.currency)
            ) {
              const sameItem = i.selectedPrices.find(
                (it) => it.currency === priceItem.currency
              )
              i.selectedPrices = i.selectedPrices.map((item) => {
                item.price = item === sameItem ? priceItem.price : item.price
                return item
              })
            } else {
              if (i.selectedPrices === undefined) {
                i.selectedPrices = []
              }
              delete priceItem.item
              i.selectedPrices.push(priceItem)
            }
          }
        }
        if (priceItem.item.id !== undefined) {
          if (priceItem.item.id === i.id) {
            if (
              i.selectedPrices !== undefined && i.selectedPrices.some((item) => item.currency === priceItem.currency)
            ) {
              const sameItem = i.selectedPrices.find(
                (it) => it.currency === priceItem.currency
              )
              i.selectedPrices = i.selectedPrices.map((item) => {
                item.price = item === sameItem ? priceItem.price : item.price
                return item
              })
            } else {
              if (i.selectedPrices === undefined) {
                i.selectedPrices = []
              }
              delete priceItem.item
              i.selectedPrices.push(priceItem)
            }
          }
        }
      })

      // console.log(this.option)
    },
    removePrice (priceItem) {
      this.option.items.forEach(i => {
        if (priceItem.item.id === i.id) {
          i.selectedPrices = i.selectedPrices.filter(
            (item) => item !== priceItem
          )
        }
      })
      this.$forceUpdate()
    },
    editPrice (item) {
      this.$refs.addPrice.open(item, item.item)
    },
    close () {
      this.$emit('close')
      this.visible = false
      this.option = undefined
      this.itemSelected = undefined
    },
    submit () {
      const that = this
      if (!this.$refs.addOptionToMenuForm.validate()) return

      let realOption = this.option
      if (this.edit) {
        realOption = {
          id: this.option.id,
          name: this.option.optionName,
          type: this.option.optionType.value
        }

        if (this.option.items !== undefined && this.option.items.length > 0) {
          realOption.option_items = []

          this.option.items.forEach(item => {
            item.selectedPrices.forEach(sp => {
              const tmp = parseFloat(sp.price)
              sp.price = tmp * 100
            })

            realOption.option_items.push({
              company_item: item,
              default: item.default,
              prices: item.selectedPrices
            })
          })
        }
      }

      that.$emit('add', realOption, this.existing)
      this.close()
    }
  }
}
</script>
<style scoped>
.compact-form {
  margin: 10px 10px -16px 10px;
}
</style>
