<template>
  <v-dialog v-model="visible" persistent max-width="460">
    <v-card class="overflow-auto">
      <v-card-title
        ><v-row class="pa-3 mb-2">
          <h3 class="text--secondary">Deaktivacija edukacijskog moda</h3>
          <v-spacer></v-spacer>
          <v-icon large @click="close">mdi-close</v-icon>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-select
            label="Postupanje nakon deaktivacije"
            dense
            outlined
            :items="deactivateOptions"
            v-model="selectedOption"
            item-text="name"
            item-value="value"
          >
          </v-select>
        </v-row>
        <v-row>
          <v-text-field
            outlined
            dense
            v-if="selectedOption === 'FISCALIZE_PART'"
            label="Broj ispravnih računa"
            v-model="numOfReceipts"
            type="number"
          ></v-text-field>
        </v-row>
        <v-row>
          <v-col>
            <v-row
              v-if="
                selectedOption !== undefined &&
                selectedOption !== 'FISCALIZE_PART'
              "
              class="pb-3"
            >
              <div
                v-if="selectedOption !== undefined"
                class="font-weight-bold primary--text"
              >
                Iznos računa:&nbsp;
              </div>
              <div
                class="font-weight-bold primary--text"
                v-if="selectedOption !== undefined"
              >
                {{ totalAmount | money(100, currency) }}
              </div>
            </v-row>
            <v-row
              v-if="
                selectedOption !== undefined &&
                selectedOption === 'FISCALIZE_PART'
              "
              class="pb-3"
            >
              <div
                v-if="selectedOption !== undefined"
                class="font-weight-bold primary--text"
              >
                Iznos pogrešnih / ukupni iznos računa:&nbsp;
              </div>
            </v-row>
            <v-row
              v-if="
                selectedOption !== undefined &&
                selectedOption === 'FISCALIZE_PART'
              "
              class="pb-3"
            >
              <div
                class="font-weight-bold primary--text justify-start"
                v-if="selectedOption !== undefined"
              >
                {{ partialAmount | money(100, currency) }} /
                {{ totalAmount | money(100, currency) }}
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn
          class="white--text okButton"
          height="45"
          @click="deactivateEduMode()"
        >
          Deaktiviraj
        </v-btn>
      </v-card-actions>
      <v-overlay v-if="loading" absolute z-index="10">
        <v-progress-circular
          indeterminate
          size="64"
          color="primary"
        ></v-progress-circular>
      </v-overlay>
    </v-card>
  </v-dialog>
</template>
<script>

import { df, auth } from '@/plugins/firebase'
import { v4 as uuidv4 } from 'uuid'
import state from '@/state'
export default {
  name: 'EducationDeactivation',
  data: () => ({
    loading: false,
    visible: false,
    submitting: false,
    register: {},
    startDeactivate: false,
    selectedOption: undefined,
    numOfReceipts: 0,
    listeners: [],
    receipts: undefined
  }),
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  components: { },
  computed: {
    amountLabel () {
      switch (this.selectedOption) {
        case 'DELETE_ALL':
          return 'Iznos računa'
        case 'FISCALIZE_PART' || 'FISCALIZE_ALL':
          return 'Iznos računa'
        default:
          return ''
      }
    },
    totalAmount () {
      let total = 0
      if (this.receipts !== undefined) {
        this.receipts.forEach(receipt => {
          total += receipt.total
        })
      }

      return total
    },
    partialAmount () {
      let total = 0
      if (this.receipts !== undefined) {
        for (let i = 0; i < (this.receipts.length - this.numOfReceipts); i++) {
          total += this.receipts[i].total
        }
      }

      return total
    },
    deactivateOptions () {
      return [
        { name: 'Svi računi su pogrešni', value: 'DELETE_ALL' },
        { name: 'Dio računa je pogrešan', value: 'FISCALIZE_PART' },
        { name: 'Svi računi su ispravni', value: 'FISCALIZE_ALL' }
      ]
    }
  },
  methods: {
    deactivateEduMode () {
      this.loading = true
      const that = this

      try {
        const reqId = uuidv4()
        this.$forceUpdate()
        const payload = {
          action: {
            operation: 'set',
            entity: 'education',
            params: {
              company_id: state.getCurrentCompany().id,
              location_id: state.getPointOfSale().id,
              cash_register_id: this.register.id,
              educational_mode: false,
              last_receipt_num: this.register.last_receipt_num,
              delete_mode: this.selectedOption,
              number_of_receipts: parseInt(this.numOfReceipts)
            }
          }
        }

        df.doc(`request/${reqId}`).set(
          {
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'education',
            payload: btoa(unescape(encodeURIComponent(JSON.stringify(payload))))
          }
        ).then((doc) => {
          setTimeout(() => {
            this.loading = false
          }, 4000)
        }).catch(function (error) {
          setTimeout(() => {
            this.loading = false

            that.showMsgBox({ text: error || 'Internal error', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
          }, 4000)
        }).finally(() => {
          setTimeout(() => {
            that.close()
          }, 4000)
        })
      } catch (error) {
        this.loading = false
        that.showMsgBox({ text: error || 'An error has occurred', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
      }
    },
    async open (register, receipts) {
      this.receipts = receipts
      this.register = register
      this.visible = true
    },
    close () {
      this.visible = false
      this.register = undefined
      this.$emit('success')
    }
  }
}
</script>
<style scoped>
