var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"460"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-card',{staticClass:"overflow-auto"},[_c('v-card-title',[_c('v-row',{staticClass:"pa-3 mb-2"},[_c('h3',{staticClass:"text--secondary"},[_vm._v("Deaktivacija edukacijskog moda")]),_c('v-spacer'),_c('v-icon',{attrs:{"large":""},on:{"click":_vm.close}},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-select',{attrs:{"label":"Postupanje nakon deaktivacije","dense":"","outlined":"","items":_vm.deactivateOptions,"item-text":"name","item-value":"value"},model:{value:(_vm.selectedOption),callback:function ($$v) {_vm.selectedOption=$$v},expression:"selectedOption"}})],1),_c('v-row',[(_vm.selectedOption === 'FISCALIZE_PART')?_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Broj ispravnih računa","type":"number"},model:{value:(_vm.numOfReceipts),callback:function ($$v) {_vm.numOfReceipts=$$v},expression:"numOfReceipts"}}):_vm._e()],1),_c('v-row',[_c('v-col',[(
              _vm.selectedOption !== undefined &&
              _vm.selectedOption !== 'FISCALIZE_PART'
            )?_c('v-row',{staticClass:"pb-3"},[(_vm.selectedOption !== undefined)?_c('div',{staticClass:"font-weight-bold primary--text"},[_vm._v(" Iznos računa:  ")]):_vm._e(),(_vm.selectedOption !== undefined)?_c('div',{staticClass:"font-weight-bold primary--text"},[_vm._v(" "+_vm._s(_vm._f("money")(_vm.totalAmount,100, _vm.currency))+" ")]):_vm._e()]):_vm._e(),(
              _vm.selectedOption !== undefined &&
              _vm.selectedOption === 'FISCALIZE_PART'
            )?_c('v-row',{staticClass:"pb-3"},[(_vm.selectedOption !== undefined)?_c('div',{staticClass:"font-weight-bold primary--text"},[_vm._v(" Iznos pogrešnih / ukupni iznos računa:  ")]):_vm._e()]):_vm._e(),(
              _vm.selectedOption !== undefined &&
              _vm.selectedOption === 'FISCALIZE_PART'
            )?_c('v-row',{staticClass:"pb-3"},[(_vm.selectedOption !== undefined)?_c('div',{staticClass:"font-weight-bold primary--text justify-start"},[_vm._v(" "+_vm._s(_vm._f("money")(_vm.partialAmount,100, _vm.currency))+" / "+_vm._s(_vm._f("money")(_vm.totalAmount,100, _vm.currency))+" ")]):_vm._e()]):_vm._e()],1)],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"white--text okButton",attrs:{"height":"45"},on:{"click":function($event){return _vm.deactivateEduMode()}}},[_vm._v(" Deaktiviraj ")])],1),(_vm.loading)?_c('v-overlay',{attrs:{"absolute":"","z-index":"10"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64","color":"primary"}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }