<template>
  <div>
    <v-dialog
      v-model="dialog"
      @keydown.esc="close"
      persistent
      max-width="300"
      style="z-index: 6"
    >
      <v-form ref="loginWithPin" @submit.prevent="submit">
        <v-card class="overflow-auto">
          <v-card-title class="d-flex" style="vertical-align: middle">
            <v-row class="pa-3 mb-2">
              <h3 class="text--secondary">Prijava putem PIN-a</h3>
              <v-spacer></v-spacer>
              <v-icon large @click="close">mdi-close</v-icon>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field
                  @focus="showKbd"
                  data-layout="numericAduro"
                  autofocus
                  dense
                  outlined
                  label="Pin"
                  v-model="pincode"
                  :rules="[rules.req]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn
              class="white--text okButton mb-2"
              height="45"
              :disabled="!pincodeValid"
              @click="submit()"
            >
              Prijavi se
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-container
      class="kbd"
      v-if="!$vuetify.breakpoint.smAndDown"
      ref="keyboard"
      v-show="oskVisible"
      :style="{ 'max-width': width }"
    >
      <div class="kbd-header"></div>
      <vue-touch-keyboard
        :options="oskOptions"
        :layout="layout"
        :cancel="hideKbd"
        :accept="accept"
        :input="input"
      />
    </v-container>
  </div>
</template>

<script>
import rules from '@/plugins/rules'
import onScreenKeyboard from '@/mixins/onScreenKeyboard'

export default {
  props: ['toolbar', 'confirmDisabled'],
  inject: ['showMsgBox'],
  mixins: [onScreenKeyboard],
  data: () => ({
    templatesDialogOpen: false,
    dialog: false,
    selectedUser: '',
    pincode: '',
    width: 300,
    users: [],
    scrollable: false,
    confirmDis: true,
    cashReg: {},
    rules: {
      req: rules.req()
    }
  }),
  computed: {
    pincodeValid () {
      if (this.pincode.length !== 4) {
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    open (users, cashReg) {
      users.forEach(user => {
        this.users.push(user.user)
      })
      this.cashReg = cashReg
      this.dialog = true
    },
    close () {
      this.dialog = false
    },
    userChoosed (selectedUser) {
      this.$emit('userSelectSuccess', { user: selectedUser, cashReg: this.cashReg })
      this.dialog = false
    },
    submit () {
      if (this.pincode === '' || this.pincode.length !== 4) {
        alert('Pin nije ispravan, molimo unesite ispravan pin')
        return
      }

      this.users.forEach(user => {
        if (user.pincode !== undefined && user.pincode !== '' && user.pincode !== null && user.pincode === this.pincode) {
          this.selectedUser = user
        }
      })

      if (this.selectedUser !== undefined && this.selectedUser !== '' && this.selectedUser.id !== '') {
        this.$emit('pinLoginSuccess', { user: this.selectedUser, cashReg: this.cashReg })
        this.dialog = false
        // this.showMsgBox({
        //   text: 'Prijavili ste se kao korisnik ' + this.selectedUser.name + ' ' + this.selectedUser.surname,
        //   actions: ['cancel'],
        //   cancelBtnText: this.$t('$vuetify.close'),
        //   color: 'error'
        // })
      } else {
        this.showMsgBox({
          text: 'Neispravan pincode, molimo pokušajte ponovno.',
          actions: ['cancel'],
          cancelBtnText: this.$t('$vuetify.close'),
          color: 'error'
        })

        this.pincode = ''
      }
    }
  },
  mounted () {
    this.setEnterAction(this.submit)
  }
}
</script>
