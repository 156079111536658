<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    max-width="470px"
    style="z-index: 6"
  >
    <v-form ref="editReceiptSettingsForm" @submit.prevent="submit">
      <v-card
        class="overflow-auto pb-5"
        :class="{ 'mobile-dialog-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h2 class="text--secondary">Postavke računa blagajne</h2>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                reverse
                autocomplete="off"
                v-model="billSequence"
                :label="$t('$vuetify.cashRegister.lastReceiptNumber')"
                :rules="[rules.req]"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                reverse
                autocomplete="off"
                v-model="offerSequence"
                :label="$t('$vuetify.cashRegister.lastOfferNumber')"
                :rules="[rules.req]"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                :label="$t('$vuetify.companies.notePOS')"
                v-model="notePOS"
                rows="5"
                outlined
              >
              </v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                :label="$t('$vuetify.companies.noteA4')"
                v-model="noteA4"
                rows="5"
                outlined
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting"
            type="submit"
          >
            {{ $t("$vuetify.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { clone } from '@/plugins/utils'
import rules from '@/plugins/rules'
import { df, auth } from '@/plugins/firebase'
import state from '@/state'
import { v4 as uuidv4 } from 'uuid'
import applicationSettings from '@/mixins/applicationSettings' // don't move.. detaches firebase listeners
import requestChecker from '@/mixins/requestChecker'

export default {
  inject: ['showMsgBox'],
  mixins: [applicationSettings, requestChecker],
  data: function () {
    return {
      visible: false,
      submitting: false,
      cashRegister: {},
      notePOS: '',
      initNotePOS: '',
      noteA4: '',
      initNoteA4: '',
      rules: {
        req: rules.req(),
        gteq: rules.gteq
      },
      billSequence: 0,
      billSequenceOrigi: 0,
      initBillSequence: 0,
      offerSequence: 0,
      offerSequenceOrigi: 0,
      initOfferSequence: 0
    }
  },
  methods: {
    open (cashRegister) {
      this.visible = true
      this.cashRegister = clone(cashRegister)
      this.notePOS = this.cashRegister.note_pos
      this.noteA4 = this.cashRegister.note_a4

      this.setCashRegisterLister()

      this.initBillSequence = this.billSequence
      this.initOfferSequence = this.offerSequence
      this.initNotePOS = this.notePOS
      this.initNoteA4 = this.noteA4

      const params = {
        note_pos: this.notePOS ? this.notePOS : '',
        note_a4: this.noteA4 ? this.noteA4 : '',
        bill_sequence: this.billSequence,
        offer_sequence: this.offerSequence
      }
      this.registerRequestParams(params)
    },
    close () {
      this.detachListeners()
      this.visible = false
    },
    setCashRegisterLister () {
      if (!this.cashRegister?.id) return
      this.listeners.push(
        df.doc(`cash_registers/${this.cashRegister.id}`).onSnapshot((doc) => {
          if (doc?.data()) {
            this.offerSequence = clone(doc.data().offer_sequence)
            this.offerSequenceOrigi = clone(doc.data().offer_sequence)
            this.billSequenceOrigi = clone(doc.data().bill_sequence)
            this.billSequence = clone(doc.data().bill_sequence)
          }
        })
      )
    },
    submit () {
      if (!this.$refs.editReceiptSettingsForm.validate()) return

      if (
        this.billSequence === this.initBillSequence &&
        this.offerSequence === this.initOfferSequence &&
        this.notePOS === this.initNotePOS &&
        this.noteA4 === this.initNoteA4
      ) {
        this.showMsgBox({ text: this.$t('$vuetify.errors.noChangesInEntities'), actions: ['ok'], cancelBtnText: 'OK', color: 'error' })
        return
      }

      if (this.cashRegister.note_pos === this.notePOS && this.cashRegister.note_a4 === this.noteA4 && this.billSequenceOrigi === this.billSequence && this.offerSequenceOrigi === this.offerSequence) {
        this.close()
        return
      }

      try {
        const reqId = uuidv4()
        const payload = {
          action: {
            operation: 'update',
            entity: 'cash_register',
            resource_id: this.cashRegister.id,
            params: {
              note_pos: this.notePOS,
              note_a4: this.noteA4,
              bill_sequence: parseInt(this.billSequence),
              offer_sequence: parseInt(this.offerSequence),
              company_id: state.getCurrentCompany().id,
              location_id: state.getPointOfSale().id
            }
          }
        }

        if (this.notePOS === undefined || this.notePOS === null) {
          payload.action.params.note_pos = ''
        }

        if (this.noteA4 === undefined || this.noteA4 === null) {
          payload.action.params.note_a4 = ''
        }

        // const cleanParams = this.getCleanParams(payload.action.params)
        // Object.keys(cleanParams).forEach((key) => {
        //   delete payload.action.params[key]
        // })

        df.doc(`request/${reqId}`)
          .set({
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'cashregister',
            payload: btoa(
              unescape(encodeURIComponent(JSON.stringify(payload)))
            )
          })
          .then(() => {
            this.$emit('editSuccess', this.cashRegister)
            this.close()
          })
          .catch(function (error) {
            // console.error('Error writing document: ', error)
            this.showMsgBox({
              text: error || 'An error has occurred',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
          })
          .finally(() => {
            this.submitting = false
          })
      } catch (err) {
        this.showMsgBox({
          text: err || 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        this.submitting = false
      }
    }
  }
}
</script>
