<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    max-width="550px"
    style="z-index: 6"
    width="300"
    height="330"
  >
    <v-form ref="editCashRegisterForm" @submit.prevent="submit">
      <v-card class="overflow-auto">
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h3 class="text--secondary">Blagajna</h3>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="pt-0">
              <v-text-field
                autofocus
                dense
                outlined
                label="Naziv"
                v-model="register.name"
                :rules="[rules.req]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0">
              <v-text-field
                dense
                outlined
                label="Oznaka blagajne"
                v-mask="'###########'"
                hint="Samo brojevi"
                v-model="register.number"
                :rules="[rules.req, rules.reservedIds]"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0">
              <v-select
                dense
                outlined
                label="Vrsta blagajne"
                v-model="register.type"
                :items="registerTypes"
                item-text="name"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0">
              <money-input
                dense
                outlined
                label="Polog na blagajni"
                v-model="register.deposit"
                ref="deposit"
                :currency="currency"
              >
              </money-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0">
              <v-select
                dense
                outlined
                label="Vrsta prijave na blagajnu"
                v-model="register.login_type"
                :items="registerLoginTypes"
                :rules="[rules.req]"
                item-text="name"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0">
              <v-select
                dense
                outlined
                label="Nakon izdavanja računa"
                v-model="register.after_receipt_behaviour"
                :items="afterReceiptBehaviours"
                :rules="[rules.req]"
                item-text="name"
                item-value="value"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0">
              <v-select
                class="pb-3"
                outlined
                dense
                label="Odaberite printer"
                :items="printers"
                item-text="name"
                item-value="id"
                return-object
                hide-details
                v-model="register.printer"
                :rules="[rules.req]"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="pb-0 pt-0">
            <v-col class="pb-0 pt-0">
              <v-select
                class="pb-3"
                outlined
                multiple
                dense
                label="Odaberite terminale"
                :items="terminals"
                item-text="name"
                return-object
                hide-details
                v-model="register.terminals"
              ></v-select>
            </v-col>
          </v-row>
          <!-- <v-row class="">
            <v-col class="pt-2">
              <v-select
                class="pb-3"
                outlined
                dense
                label="Odaberite način naplate"
                :items="terminalTypes"
                item-text="name"
                item-value="id"
                hide-details
                v-model="register.terminal_type"
              ></v-select>
            </v-col>
          </v-row> -->
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting"
            type="submit"
          >
            Spremi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <add-printer ref="addPrinter"></add-printer>
  </v-dialog>
</template>
<script>

import rules from '@/plugins/rules'
import { df, auth } from '@/plugins/firebase'
import { v4 as uuidv4 } from 'uuid'
import state from '@/state'
import { clone, duck, currencyToFloat } from '@/plugins/utils'
import MoneyInput from '@/components/MoneyInput'
import AddPrinter from '@/modules/point-of-sale/components/printer-configuration/AddPrinter'

export default {
  name: 'AddCashRegister',
  data: () => ({
    visible: false,
    submitting: false,
    error: {
      show: false,
      message: undefined
    },
    rules: {
      req: rules.req(),
      eq: rules.eq
    },
    registerName: undefined,
    registerNumber: undefined,
    maxHolding: undefined,
    register: {},
    initialRegister: {},
    registerDefults: {},
    registerTypes: [],
    terminals: [],
    registerLoginTypes: [],
    afterReceiptBehaviour: undefined,
    afterReceiptBehaviours: [],
    printers: [],
    selectedPrinter: undefined,
    listeners: [],
    currency: 'EUR'
    // terminalTypes: [{
    //   name: 'Prije izdavanja računa',
    //   id: 'before'
    // }, {
    //   name: 'Nakon izdavanja računa',
    //   id: 'after'
    // }]
  }),
  inject: ['showMsgBox', 'showLoader', 'hideLoader', 'confirm', 'confirmClose'],
  components: { MoneyInput, AddPrinter },

  computed: {
    reservedIds () {
      return state.registerIds
    }
  },
  methods: {
    async open (register) {
      var temp = []
      if (register.terminals) {
        Object.keys(register.terminals).forEach(key => {
          temp.push(register.terminals[key])
        })

        register.terminals = temp
      }

      const printers = []
      this.initialRegister = clone(register)
      this.initialRegister.deposit = register.deposit?.amt || 0
      this.register = clone(register)

      printers.push({ name: 'POS printer 58mm (T58)', value: 'T58', id: 'T58', library: 'NONE', width: 'T58' })
      printers.push({ name: 'POS printer 80mm (T80)', value: 'T80', id: 'T80', library: 'NONE', width: 'T80' })
      printers.push({ name: 'Klasični printer (A4)', value: 'A4', id: 'A4', library: 'NONE', width: 'A4' })
      const terminals = []
      const query = df
        .collection(`location_printers/${state.getPointOfSale().id}/printers`)
      const listener = query
        .onSnapshot((doc) => {
          doc.docs.forEach((rec) => {
            printers.push(rec.data())
          })
          this.printers = clone(printers.sort((a, b) => a.name.localeCompare(b.name)))
          state.setInstalledPrinters(this.printers)
        }
        )
      this.listeners.push(listener)

      // this.listeners.push(listener)
      this.submitting = false
      this.registerDefults = Object.assign(this.registerDefults, register)

      // this.register = Object.assign(this.register, register)
      this.register.deposit = register.deposit?.amt || 0

      if (this.register.printer && this.register.printer !== '') {
        this.printers.forEach(printer => {
          if (printer.id === this.register.printer) {
            this.register.printer = printer
          }
        })
      }

      await df
        .collection(`location_poses/${state.getPointOfSale().id}/poses`)
        .onSnapshot((doc) => {
          doc.docs.forEach((rec) => {
            terminals.push(rec.data())
          })

          this.terminals = clone(terminals.sort((a, b) => a.name.localeCompare(b.name)))

          if (this.register.terminals && Object.keys(this.register.terminals).length > 0) {
            this.register.terminals = this.register.terminals.filter(term => this.terminals.findIndex(t => t.id === term.id) > -1)
          }

          this.$forceUpdate()
        })

      const number = parseInt(clone(this.register.number))
      this.rules.reservedIds = (val) => {
        return (!this.reservedIds.includes(parseInt(val)) || (this.reservedIds.includes(parseInt(val)) && number === parseInt(val))) ||
          `Blagajna s oznakom ${val} već postoji!`
      }
      this.registerTypes = [
        { name: this.$t('$vuetify.cashRegister.registerTypes.mp'), value: 'MP', disabled: !state.getCurrentCompany().certificate },
        { name: this.$t('$vuetify.cashRegister.registerTypes.vp'), value: 'VP' },
        { name: this.$t('$vuetify.cashRegister.registerTypes.all'), value: 'ALL', disabled: !state.getCurrentCompany().certificate }
      ]

      this.registerLoginTypes = [
        { name: this.$t('$vuetify.cashRegister.registerLoginTypes.default'), value: 'DEFAULT' },
        { name: this.$t('$vuetify.cashRegister.registerLoginTypes.selectUser'), value: 'SELECT_USER' },
        { name: this.$t('$vuetify.cashRegister.registerLoginTypes.pincode'), value: 'PINCODE' }
      ]

      this.afterReceiptBehaviours = [
        { name: this.$t('$vuetify.cashRegister.afterReceiptBehaviours.emptyBasket'), value: 'EMPTY_BASKET' },
        { name: this.$t('$vuetify.cashRegister.afterReceiptBehaviours.showCashRegs'), value: 'SHOW_CASH_REGS' },
        { name: this.$t('$vuetify.cashRegister.afterReceiptBehaviours.showAllReceipts'), value: 'SHOW_ALL_RECEIPTS' }
      ]

      this.visible = true

      this.setOptionalParams()
    },
    close () {
      this.$emit('close')
      this.visible = false
      this.$refs.editCashRegisterForm.reset()
    },
    setOptionalParams () { // TODO: Make unique params checker for dirty (and different v-model) fields.
      this.registerName = clone(this.register.name)
      this.registerNumber = clone(this.register.number)
    },
    getOptionalParams () {
      const returnObject = {
        name: this.register.name,
        number: parseInt(this.register.number),
        type: this.register.type
      }

      if (this.register.printer) {
        returnObject.printer = this.register.printer.id
      }

      if (this.register.terminals && this.register.terminals.length > 0) {
        returnObject.terminals = this.register.terminals
      } else {
        returnObject.terminals = []
      }

      if (this.register.terminal_type) {
        returnObject.terminal_type = this.register.terminal_type
      }

      if (duck(this.register.name, this.registerName)) delete returnObject.name
      if (this.register.number === this.registerNumber) delete returnObject.number
      const deposit = this.$refs.deposit.currencyToFloat(this.register.deposit) * 100
      if (deposit && deposit > 0) returnObject.deposit = deposit
      returnObject.login_type = this.register.login_type
      returnObject.after_receipt_behaviour = this.register.after_receipt_behaviour
      return returnObject
    },
    submit () {
      const oldRegister = clone(this.register)
      const newRegister = clone(this.initialRegister)
      oldRegister.deposit = currencyToFloat(oldRegister.deposit) * 100
      const printerId = oldRegister.printer.id ?? oldRegister.printer
      oldRegister.printer = (printerId)
      newRegister.number = String(newRegister.number)

      if (duck(oldRegister, newRegister)) {
        this.showMsgBox({ text: this.$t('$vuetify.errors.noChangesInEntities'), actions: ['ok'], cancelBtnText: 'OK', color: 'error' })
        return
      }

      const that = this
      if (!this.$refs.editCashRegisterForm.validate()) return
      this.submitting = true

      try {
        const reqId = uuidv4()
        this.$forceUpdate()
        const payload = {
          action: {
            resource_id: this.register.id,
            operation: 'update',
            entity: 'cash_register',
            params: {
              company_id: state.getCurrentCompany().id,
              location_id: state.getPointOfSale().id,
              ...this.getOptionalParams()
            }
          }
        }

        df.doc(`request/${reqId}`).set(
          {
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'cashregister',
            payload: btoa(unescape(encodeURIComponent(JSON.stringify(payload))))
          }
        ).then((doc) => {
          that.$emit('editSuccess', { id: this.register.id, name: this.register.name })
          that.$refs.editCashRegisterForm.reset()
          that.visible = false
          this.initialRegister = null
        }).catch(function (error) {
          // console.error('Error writing document: ', error)
          that.showMsgBox({ text: error || 'Internal error', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
          that.submitting = false
        }).finally(() => {
        })
      } catch (error) {
        that.showMsgBox({ text: error || 'An error has occurred', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
        that.submitting = false
      }
    }
  }
}
</script>
<style scoped>
