<template>
  <v-dialog v-model="visible" @keydown.esc="close" persistent scrollable max-width="40%" max-height="100%" style="z-index: 6">
    <v-form ref="addTagToMenuForm" @submit.prevent="submit">
      <v-card class="overflow-auto pb-5" :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }">
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="mb-2">
            <v-col class="d-flex justify-start">
              <h2 class="text--secondary">Tag</h2>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex justify-end">
              <v-icon large @click="close">mdi-close</v-icon>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="10" class="d-flex justify-start">
                <v-select flat dense outlined class="pt-1" :items="tagTypes" v-model="tagType" return-object item-text="name" label="Vrsta taga" autofocus :rules="[rules.req]"></v-select>
              </v-col>
              <v-col cols="2" class="d-flex justify-end">
                <v-btn v-if="!addButtonHidden" outlined class="white--text okButton mx-2 mt-1" height="40" @keyup.enter="addTag" @click.stop="addTag" :disabled="!tagType">Odaberi</v-btn>
              </v-col>
            </v-row>
            <v-card v-if="tag !== undefined && tag !== null" outlined>
              <v-card-text>
                <v-row>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field dense outlined class="compact-form" label="Ime taga" v-model="tag.tagName"></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-for="(item, itKey) in tag.tagTexts" :key="itKey">
                  <v-col cols="7" style="padding: 0" class="d-flex justify-start">
                    <v-text-field outlined dense class="compact-form" label="Text" disabled :value="item.name"></v-text-field>
                  </v-col>
                  <v-col cols="3" style="padding: 0" class="d-flex justify-end">
                    <v-checkbox outlined v-model="item.default" style="margin-top: 14px" class="compact-form" @change="changeDefault(item)" label="Zadana stavka"></v-checkbox>
                  </v-col>
                  <v-col cols="2" style="padding: 0" class="d-flex justify-end">
                    <v-btn plain outlined dense style="margin: 10px 6px 0 0; height: 40px" class="compact-form" @click="removeItemFromTag(item)">Ukloni</v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="10" style="padding: 0">
                    <v-text-field dense outlined class="compact-form" label="Tekst" v-model="tag.tagText" item-text="name"></v-text-field>
                  </v-col>
                  <v-col cols="2" style="padding: 0">
                    <v-btn dense height="40px" :disabled="!tag.tagName || !tag.tagText" class="white--text okButton compact-form" @keyup.enter="addItemToTag(tag.tagText)" @click.stop="addItemToTag(tag.tagText)">Dodaj</v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn class="white--text okButton" height="45" :loading="submitting" :disabled="submitting" type="submit">
            Spremi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import rules from '@/plugins/rules'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'AddTagToMenu',
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  data: () => ({
    visible: false,
    name: undefined,
    existing: false,
    submitting: false,
    rules: {
      req: rules.req('Required field')
    },
    items: [],
    itemSelected: undefined,
    tagType: { name: 'Višestruki odabir - obavezan', value: 'manC' },
    tag: undefined,
    edit: false,
    addButtonHidden: false
  }),
  computed: {
    tagTypes () {
      return [
        { name: 'Višestruki odabir - obavezan', value: 'manC' },
        { name: 'Višestruki odabir - opcionalan', value: 'optC' },
        { name: 'Jednostruki odabir - obavezan', value: 'manR' },
        { name: 'Jednostruki odabir - opcionalan', value: 'optR' }
      ]
    }
  },
  watch: {
    tag: {
      immediate: false,
      deep: true,
      handler () {
        this.addButtonHidden = true
      }
    }
  },
  methods: {
    findType (type) {
      switch (type) {
        case 'manC':
          return 'Višestruki odabir - obavezan'
        case 'optC':
          return 'Višestruki odabir - opcionalan'
        case 'manR':
          return 'Jednostruki odabir - obavezan'
        case 'optR':
          return 'Jednostruki odabir - opcionalan'
      }
    },
    changeDefault (item) {
      if (this.tagType.value === 'manC' || this.tagType.value === 'optC') {
        return
      }
      if (this.tag.tagTexts !== undefined && this.tag.tagTexts !== null && this.tag.tagTexts.length > 0) {
        const tag = this.tag.tagTexts.find(t => t.id === item.id)
        this.tag.tagTexts.forEach((i) => {
          i.default = i.id === tag.id
        })
        this.$forceUpdate()
      }
    },
    addItemToTag (tagTextSelected) {
      if (this.tag.tagTexts === undefined || this.tag.tagTexts === null) {
        this.tag.tagTexts = []
      }

      this.tag.tagTexts.push({ id: uuidv4(), name: tagTextSelected, default: false })
      this.tag.tagText = undefined
    },
    removeItemFromTag (tagTextSelected) {
      this.tag.tagTexts = this.tag.tagTexts.filter(item => {
        if (item.id !== tagTextSelected.id) {
          return item
        }
      })

      const temp = this.tag.tagTexts
      this.tag.tagTexts = []
      this.tag.tagTexts = temp

      this.$forceUpdate()
    },
    removeTag (rmItem) {
      this.tagTexts = this.tagTexts.filter(item => {
        if (item.id !== rmItem.id) {
          return item
        }
      })
    },
    addTag () {
      const newTag = {
        id: uuidv4(),
        tagType: this.tagType
      }

      this.tag = newTag
    },
    async open (existing = undefined, edit = false) {
      this.visible = true
      this.addButtonHidden = false
      this.edit = edit

      if (existing !== undefined && !edit) {
        this.existing = true
        this.tag = existing
      }

      // Transformacija za edit - NE DIRATI
      if (existing !== undefined && edit) {
        this.existing = true

        const tagType = {
          name: this.findType(existing.type),
          value: existing.type
        }

        this.tag = {
          id: existing.id,
          tagName: existing.name,
          tagType: tagType
        }

        if (existing.tag_texts !== undefined && existing.tag_texts.length > 0) {
          this.tag.tagTexts = []
          existing.tag_texts.forEach(ti => {
            this.tag.tagTexts.push({
              id: ti.id,
              name: ti.text,
              default: ti.default
            })
          })
        }
      }
    },
    close () {
      this.$emit('close')
      this.visible = false
      this.tag = undefined
      this.tagText = undefined
    },
    submit () {
      const that = this
      if (!this.$refs.addTagToMenuForm.validate()) return
      let realTag = this.tag

      if (this.edit) {
        realTag = {
          id: this.tag.id,
          name: this.tag.tagName,
          type: this.tag.tagType.value
        }

        if (this.tag.tagTexts !== undefined && this.tag.tagTexts.length > 0) {
          realTag.tag_texts = []

          this.tag.tagTexts.forEach(ti => {
            realTag.tag_texts.push({
              id: ti.id,
              text: ti.name,
              default: ti.default
            })
          })
        }
      }

      that.$emit('add', realTag, this.existing)
      this.close()
    }
  }
}
</script>
<style scoped>
.compact-form {
  margin: 10px 10px -16px 10px;
}
</style>
